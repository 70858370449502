import {ReactNode, useState} from 'react';
import {Button} from 'reactstrap';
import {useNavigate} from 'react-router-dom';

import {useUserContext} from '@reasoncorp/kyber-js';

import {Parcel} from '../../types';
import {BonaFideCreateModal} from '../bonaFides';
import {InterestWaiverCreateModal} from '../interestWaivers';

type Props = {
  renderButtons: () => ReactNode
  parcel: Parcel
  onRecordSave?: () => void
  createButtonsDisabled: boolean
  denialId?: number
  bonaFideId?: number
  interestWaiverId?: number
  currentRecordType?: string
}

const RecordNavBar = ({
                        renderButtons,
                        onRecordSave,
                        createButtonsDisabled,
                        parcel,
                        denialId,
                        bonaFideId,
                        interestWaiverId,
                        currentRecordType
                      }: Props) => {
  const {permissions} = useUserContext();
  const [bonaFideModalIsOpen, setBonaFideModalIsOpen] = useState(false);
  const [interestWaiverModalIsOpen, setInterestWaiverModalIsOpen] = useState(false);
  const navigate = useNavigate();

  const renderBonaFideButtons = () => {
    return bonaFideId ? <Button color="primary"
                                onClick={() => navigate(`/bona-fides/${bonaFideId}`)}
                                className="mr-2">
        View Bona Fide
      </Button>
      : <Button color="primary"
                className="mr-2"
                disabled={createButtonsDisabled || !permissions.hasWriteAccess}
                onClick={() => setBonaFideModalIsOpen(true)}>
        Create Bona Fide
      </Button>;
  };

  const renderInterestWaiverButtons = () => {
    return interestWaiverId ? <Button color="primary"
                                      onClick={() => navigate(`/interest-waivers/${interestWaiverId}`)}
                                      className="mr-2">
        View Interest Waiver
      </Button>
      : <Button color="primary"
                className="mr-2"
                disabled={createButtonsDisabled || !permissions.hasWriteAccess}
                onClick={() => setInterestWaiverModalIsOpen(true)}>
        Create Interest Waiver
      </Button>;
  };

  const renderDenialButtons = () => {
    return <Button color="primary"
                   disabled={denialId === undefined || denialId === null}
                   className="mr-2"
                   onClick={() => navigate(`/denials/${denialId}`)}>
      View Denial
    </Button>;
  };

  return <>
    {currentRecordType !== 'DENIAL' && currentRecordType !== 'QUALIFIED_ERROR' && renderDenialButtons()}
    {currentRecordType !== 'BONA_FIDE' && currentRecordType !== 'QUALIFIED_ERROR' && renderBonaFideButtons()}
    {currentRecordType !== 'INTEREST_WAIVER' && currentRecordType !== 'QUALIFIED_ERROR' && renderInterestWaiverButtons()}
    {renderButtons()}
    <BonaFideCreateModal isOpen={bonaFideModalIsOpen}
                         parcel={parcel}
                         onSave={onRecordSave}
                         denialId={denialId}
                         interestWaiverId={interestWaiverId}
                         toggleOpen={() => setBonaFideModalIsOpen(!bonaFideModalIsOpen)}/>
    <InterestWaiverCreateModal isOpen={interestWaiverModalIsOpen}
                               parcel={parcel}
                               denialId={denialId}
                               bonaFideId={bonaFideId}
                               onSave={onRecordSave}
                               toggleOpen={() => setInterestWaiverModalIsOpen(!interestWaiverModalIsOpen)}/>
  </>;
};

export default RecordNavBar;