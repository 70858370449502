import {BlobResponse, BonaFideResponse, BonaFideSearchResponse, DocumentResponse} from '../types';
import {BonaFideRequest, BonaFideSearchRequest} from '../types/request';
import {proServApi} from './apiUtils';

export const find = (id: number): Promise<BonaFideResponse> => proServApi.getWithJsonResponse(
  `/bona-fides/${id}`
);

export const create = (bonaFideRequest: BonaFideRequest): Promise<BonaFideResponse> =>
  proServApi.postWithJsonResponse(
    `/bona-fides`,
    {
      body: JSON.stringify(bonaFideRequest)
    }
  );

export const search = (bonaFideSearchRequest: BonaFideSearchRequest): Promise<BonaFideSearchResponse[]> =>
  proServApi.postWithJsonResponse(
    '/bona-fides/search',
    {
      body: JSON.stringify(bonaFideSearchRequest)
    }
  );

export const save = (id: number, bonaFideRequest: BonaFideRequest): Promise<BonaFideResponse> =>
  proServApi.putWithJsonResponse(
    `/bona-fides/${id}`,
    {
      body: JSON.stringify(bonaFideRequest)
    }
  );

export const findDocuments = (id: number): Promise<DocumentResponse[]> => proServApi.getWithJsonResponse(
  `/bona-fides/${id}/documents`
);

export const download = (id: number): Promise<BlobResponse> => proServApi.getWithBlobResponse(
  `/bona-fides/${id}/download`
);