import {useEffect, useState} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import {useParams} from 'react-router-dom';

import {ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {
  CallsCard,
  DocumentsCard,
  NotesCard,
  OwnerCard,
  ParcelCard,
  RecordNavBar,
  RelatedParcelRecordsCard
} from '../components/shared';
import {
  BonaFideBillingInformationCard,
  BonaFideCancellationCard,
  BonaFideDenialInformationCard,
  BonaFideDenialYearCard,
  BonaFideInformationCard,
  BonaFideReviewCard
} from '../components/bonaFides';
import {bonaFideApi, callApi, noteApi} from '../api';
import {BonaFideResponse, Call, DocumentResponse, Note} from '../types';
import {BonaFideRequest, OwnerRequest, ParcelRequest} from '../types/request';
import * as messages from '../messages';

const BonaFide = () => {
  const id = Number(useParams<{id: string}>().id);
  const [bonaFide, setBonaFide] = useState<BonaFideResponse | undefined>(undefined);
  const [documents, setDocuments] = useState<DocumentResponse[]>([]);
  const [notes, setNotes] = useState<Note[]>([]);
  const [calls, setCalls] = useState<Call[]>([]);
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: false, loadError: false, processing: true});

  useEffect(() => {
    const loadBonaFide = async () => {
      setLoadingState({loading: true, loadError: false, processing: false});
      try {
        const [bonaFide, documents] = await Promise.all([
          bonaFideApi.find(id),
          bonaFideApi.findDocuments(id)
        ]);

        setBonaFide(bonaFide);
        setDocuments(documents);

        const [notes, calls] = await Promise.all([
          noteApi.findAll(bonaFide.parcel.id),
          callApi.findAll(bonaFide.parcel.id)
        ]);
        setNotes(notes);
        setCalls(calls);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE);
        setLoadingState({loadError: true, loading: false, processing: false});
      }
    };
    loadBonaFide().then();
  }, [id, showErrorAlert]);

  const loadBonaFide = async () => {
    try {
      const [bonaFide, documents] = await Promise.all([
        bonaFideApi.find(id),
        bonaFideApi.findDocuments(id)
      ]);

      setBonaFide(bonaFide);
      setDocuments(documents);

      const [notes, calls] = await Promise.all([
        noteApi.findAll(bonaFide.parcel.id),
        callApi.findAll(bonaFide.parcel.id)
      ]);
      setNotes(notes);
      setCalls(calls);
    } catch (e) {
      showErrorAlert(messages.API_FAILURE);
    }
  };

  const handleSave = async (bonaFideRequest: BonaFideRequest) => {
    try {
      setLoadingState({loading: false, loadError: false, processing: true});
      const bonaFide = await bonaFideApi.save(id, bonaFideRequest);
      setBonaFide(bonaFide);
      showSuccessAlert(messages.BONA_FIDE_SAVE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.BONA_FIDE_SAVE_FAILURE);
    }
    setLoadingState({loading: false, loadError: false, processing: false});
  };

  const handleOwnerSave = (owner: OwnerRequest) => handleSave({
    ...bonaFide,
    owner
  } as BonaFideRequest);

  const handleParcelSave = (parcel: ParcelRequest) => handleSave({
    ...bonaFide,
    parcel
  } as BonaFideRequest);

  const handleDownloadPdf = async () => {
    try {
      setLoadingState({loading: false, loadError: false, processing: true});
      await bonaFideApi.download(id);
    } catch (e) {
      showErrorAlert(messages.DOCUMENT_DOWNLOAD_FAILURE);
    }
    setLoadingState({loading: false, loadError: false, processing: false});
  };

  const renderButtons = () => <>
    <Button color="primary"
            disabled={loadingState.processing}
            onClick={async () => await handleDownloadPdf()}
            className="mr-2">
      View PDF
    </Button>
  </>;

  return <Container fluid>
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loadError && !loadingState.loading && bonaFide && <>
      <Row className="mb-4">
        <Col className="align-self-center" sm="3">
          <h1 className="h5 mb-0">Bona Fide: {bonaFide.fileNumber}</h1>
        </Col>
        <Col className="d-flex justify-content-end" sm="9">
          <RecordNavBar parcel={bonaFide.parcel}
                        bonaFideId={bonaFide.id}
                        denialId={bonaFide.denialId}
                        interestWaiverId={bonaFide.interestWaiverId}
                        createButtonsDisabled={bonaFide.year < new Date().getFullYear() - 5}
                        onRecordSave={loadBonaFide}
                        currentRecordType="BONA_FIDE"
                        renderButtons={renderButtons}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <BonaFideBillingInformationCard bonaFide={bonaFide}
                                          onSave={handleSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <ParcelCard parcel={bonaFide.parcel}
                      onSave={handleParcelSave}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm="6">
          <BonaFideDenialInformationCard bonaFide={bonaFide}
                                         onSave={handleSave}/>
        </Col>
        <Col sm="6">
          <OwnerCard owner={bonaFide.owner}
                     onSave={handleOwnerSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <BonaFideDenialYearCard bonaFide={bonaFide}
                                  onSave={handleSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm="6">
          <BonaFideInformationCard bonaFide={bonaFide}
                                   onSave={handleSave}/>
        </Col>
        <Col sm="6">
          <BonaFideCancellationCard bonaFide={bonaFide}
                                    onSave={handleSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <BonaFideReviewCard bonaFide={bonaFide}
                              onSave={handleSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="col-6">
          <RelatedParcelRecordsCard parcelId={bonaFide.parcel.id}/>
        </Col>
      </Row>
      <NotesCard notes={notes}
                 parcelId={bonaFide.parcel.id}
                 onSaveSuccess={loadBonaFide}/>
      <CallsCard calls={calls}
                 parcelId={bonaFide.parcel.id}
                 onSaveSuccess={loadBonaFide}/>
      <DocumentsCard documents={documents}
                     parcelId={bonaFide.parcel.id}
                     onSuccess={loadBonaFide}/>
    </>}
  </Container>;
};

export default BonaFide;