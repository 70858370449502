import {useCallback, useState} from 'react';
import {Card, CardHeader, Table} from 'reactstrap';

import {ExportBatchLite} from '@reasoncorp/kyber-js';

import {formatDateTime} from '../../util';

type Props = {
  onItemSelect: (exportBatch: ExportBatchLite) => void
  exportBatches: ExportBatchLite[]
}

const ImportBatchesTable = ({
                              onItemSelect,
                              exportBatches
                            }: Props) => {
  const [selectedBatch, setSelectedBatch] = useState<ExportBatchLite>(exportBatches[0]);

  const handleItemSelect = useCallback((exportBatchLite: ExportBatchLite) => {
    setSelectedBatch(exportBatchLite);
    onItemSelect(exportBatchLite);
  }, [onItemSelect]);

  const renderExportBatch = (exportBatchLite: ExportBatchLite, index: number) => {
    return (
      <tr key={exportBatchLite.batchId}
          className={exportBatchLite.batchId !== selectedBatch?.batchId ? 'cursor-pointer' : 'font-weight-bold'}
          onClick={() => handleItemSelect(exportBatchLite)}>
        <td className="align-left">
          {index === 0 ? 'Most Recent' : formatDateTime(exportBatchLite.createdAt)}
        </td>
      </tr>
    );
  };

  return <Card>
    <CardHeader>Report History</CardHeader>
    <Table bordered striped responsive>
      <tbody>
        {exportBatches.length > 0 && exportBatches.map(renderExportBatch)}
        {exportBatches.length === 0 && <tr>
          <td>
            No Import Batches Available.
          </td>
        </tr>}
      </tbody>
    </Table>
  </Card>;
};

export default ImportBatchesTable;