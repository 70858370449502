import {useContext} from 'react';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';
import {useNavigate} from 'react-router-dom';

import {FormikDatePicker, FormikInput, FormikNumberInput, FormikSelect, useAlerts} from '@reasoncorp/kyber-js';

import {Parcel} from '../../types';
import {BonaFideRequest} from '../../types/request';
import {bonaFideCreateSchema} from '../../schema/bonaFide';
import {CollectionContext} from '../../contexts';
import {bonaFideApi} from '../../api';
import * as messages from '../../messages';

type Props = {
  isOpen: boolean
  toggleOpen: () => void
  parcel?: Parcel
  onSave?: () => void
  denialId?: number
  interestWaiverId?: number
}

const BonaFideCreateModal = ({
                               isOpen,
                               toggleOpen,
                               parcel,
                               onSave,
                               denialId,
                               interestWaiverId
                             }: Props) => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const {collections} = useContext(CollectionContext);
  const navigate = useNavigate();

  const initialValues: BonaFideRequest = {
    parcel: {
      parcelNumber: parcel?.parcelNumber ?? '',
      county: parcel?.county ?? '',
      localUnit: parcel?.localUnit ?? '',
      address: {
        directionPrefix: parcel?.address.directionPrefix ?? '',
        directionSuffix: parcel?.address.directionSuffix ?? '',
        streetNumber: parcel?.address.streetNumber ?? '',
        streetName: parcel?.address.streetName ?? '',
        streetType: parcel?.address.streetType ?? '',
        unit: parcel?.address.unit ?? '',
        city: parcel?.address.city ?? '',
        state: 'MI',
        zip: parcel?.address.zip ?? ''
      }
    },
    fileInformation: {
      numberReceived: '',
      receivedOn: null,
      yearsBilled: ''
    },
    denialId,
    interestWaiverId
  };

  const handleSave = async (bonaFideRequest: BonaFideRequest, formikHelpers: FormikHelpers<BonaFideRequest>) => {
    try {
      const bonaFide = await bonaFideApi.create(bonaFideRequest);
      toggleOpen();
      showSuccessAlert(messages.BONA_FIDE_SAVE_SUCCESSFUL);
      formikHelpers.resetForm();
      if (onSave) {
        await onSave();
      } else {
        navigate(`/bona-fides/${bonaFide.id}`);
      }
    } catch (e) {
      showErrorAlert(messages.BONA_FIDE_SAVE_FAILURE);
    }
  };

  return <Modal isOpen={isOpen}
                toggle={toggleOpen}
                autoFocus={false}
                aria-modal={true}
                returnFocusAfterClose={true}
                size="xl">
    <ModalHeader toggle={toggleOpen}
                 tag="h2"
                 className="h5">
      Create New Bona Fide
    </ModalHeader>
    <h2 className="h6 bg-light p-3">Property Information</h2>
    <>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={bonaFideCreateSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col sm="4">
                <FormikInput autoFocus
                             name="parcel.parcelNumber"
                             labelText="Parcel #"/>
              </Col>
              <Col sm="4">
                <FormikSelect name="parcel.county"
                              labelText="County">
                  <option value="">Select</option>
                  {collections?.counties.map(county =>
                    <option value={county} key={county}>{county}</option>)
                  }
                </FormikSelect>
              </Col>
              <Col sm="4">
                <FormikInput name="parcel.localUnit"
                             labelText="City/Township"/>
              </Col>
            </Row>
            <Row>
              <Col sm="2">
                <FormikInput name="parcel.address.directionPrefix"
                             labelText="Dir. Prefix"/>
              </Col>
              <Col sm="2">
                <FormikInput name="parcel.address.streetNumber"
                             labelText="Street #"/>
              </Col>
              <Col sm="3">
                <FormikInput name="parcel.address.streetName"
                             labelText="Street Name"/>
              </Col>
              <Col sm="2">
                <FormikInput name="parcel.address.streetType"
                             labelText="Street Type"/>
              </Col>
              <Col sm="2">
                <FormikInput name="parcel.address.directionSuffix"
                             labelText="Dir. Suffix"/>
              </Col>
              <Col sm="1">
                <FormikInput name="parcel.address.unit"
                             labelText="APT #"/>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <FormikInput name="parcel.address.city"
                             labelText="City"/>
              </Col>
              <Col sm="4">
                <FormikInput name="parcel.address.state"
                             disabled={true}
                             labelText="State Abbr"/>
              </Col>
              <Col sm="4">
                <FormikInput name="parcel.address.zip"
                             labelText="Zip Code"/>
              </Col>
            </Row>
          </ModalBody>
          <h2 className="h6 bg-light p-3">File Information</h2>
          <ModalBody>
            <Row>
              <Col sm="4">
                <FormikDatePicker name="fileInformation.receivedOn"
                                  labelText="Date Received"/>
              </Col>
              <Col sm="4">
                <FormikNumberInput name="fileInformation.yearsBilled"
                                   labelText="Number of Years Billed"/>
              </Col>
              <Col sm="4">
                <FormikNumberInput name="fileInformation.numberReceived"
                                   labelText="Bona Fides Received"/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row className="justify-content-end">
              <Col>
                <Button color="primary"
                        className="mr-2"
                        disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
                        onClick={formikProps.submitForm}>
                  Save
                </Button>
                <Button color="secondary" onClick={toggleOpen}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </>)}
      </Formik>
    </>
  </Modal>;
};

export default BonaFideCreateModal;