import {useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikInput, FormikSelect, useUserContext, UsState} from '@reasoncorp/kyber-js';

import {Address} from '../../types';
import {addressSchema} from '../../schema';

type Props = {
  mailingAddress: Address
  onSave: (mailingAddressRequest: Address) => void
}

const MailingAddressCard = ({
                              mailingAddress,
                              onSave
                            }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {permissions} = useUserContext();

  const handleSave = async (mailingAddressRequest: Address) => {
    setModalIsOpen(false);
    await onSave(mailingAddressRequest);
  };

  const initialValues: Address = {
    directionPrefix: mailingAddress.directionPrefix,
    directionSuffix: mailingAddress.directionSuffix,
    streetNumber: mailingAddress.streetNumber,
    streetName: mailingAddress.streetName,
    streetType: mailingAddress.streetType,
    unit: mailingAddress.unit,
    city: mailingAddress.city,
    state: mailingAddress.state,
    zip: mailingAddress.zip
  };

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col className="align-self-center" sm="6">
            Mailing Information
          </Col>
          <Col className="justify-content-end d-flex" sm="6">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive>
        <thead>
          <tr>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{mailingAddress.fullDisplay}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="xl">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Mailing Address
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={addressSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col sm="2">
                <FormikInput autoFocus
                             name="directionPrefix"
                             labelText="Dir. Prefix"/>
              </Col>
              <Col sm="2">
                <FormikInput name="streetNumber"
                             labelText="Street #"/>
              </Col>
              <Col sm="3">
                <FormikInput name="streetName"
                             labelText="Street Name"/>
              </Col>
              <Col sm="2">
                <FormikInput name="streetType"
                             labelText="Street Type"/>
              </Col>
              <Col sm="2">
                <FormikInput name="directionSuffix"
                             labelText="Dir. Suffix"/>
              </Col>
              <Col sm="1">
                <FormikInput name="unit"
                             labelText="APT #"/>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <FormikInput name="city"
                             labelText="City"/>
              </Col>
              <Col sm="4">
                <FormikSelect name="state"
                              labelText="State">
                  <option value="">Select</option>
                  {Object.keys(UsState).map(state =>
                    <option value={state} key={state}>{state}</option>)}
                </FormikSelect>
              </Col>
              <Col sm="4">
                <FormikInput name="zip"
                             labelText="Zip"/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-end">
            <Button color="primary"
                    className="mr-2"
                    disabled={formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
                    onClick={formikProps.submitForm}>
              Save
            </Button>
            <Button color="secondary"
                    onClick={() => setModalIsOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default MailingAddressCard;