import {useState} from 'react';
import {Button, Card, CardHeader, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikDatePicker, FormikNumberInput, useUserContext} from '@reasoncorp/kyber-js';

import {QualifiedErrorResponse} from '../../types';
import {formatDate} from '../../util';
import {QualifiedErrorRequest} from '../../types/request';
import {qualifiedErrorFileInformationSchema} from '../../schema/qualifiedError';

type Props = {
  qualifiedError: QualifiedErrorResponse
  onSave: (qualifiedErrorRequest: QualifiedErrorRequest) => void
}

type Values = {
  receivedOn?: Date
  yearsUnderReview?: number | string
}

const QualifiedErrorFileInformationCard = ({
                                             qualifiedError,
                                             onSave
                                           }: Props) => {
  const {permissions} = useUserContext();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleOpen = () => setModalIsOpen(!modalIsOpen);

  const handleSave = async (values: Values) => {
    const qualifiedErrorRequest = {
      ...qualifiedError,
      ...values
    } as QualifiedErrorRequest;
    await onSave(qualifiedErrorRequest);
    setModalIsOpen(false);
  };

  const initialValues = {
    receivedOn: qualifiedError?.receivedOn,
    yearsUnderReview: qualifiedError?.yearsUnderReview ?? ''
  };

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            File Information
          </Col>
          <Col sm="6" className="justify-content-end d-flex">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th className="w-50">Date Received</th>
            <th className="w-50">Years Under Review</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{formatDate(qualifiedError.receivedOn)}&nbsp;</td>
            <td>{qualifiedError.yearsUnderReview}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={toggleOpen}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="md">
      <ModalHeader toggle={toggleOpen}
                   tag="h2"
                   className="h5">
        Edit File Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={qualifiedErrorFileInformationSchema}>
        {(formikProps) => (<Form onSubmit={formikProps.submitForm} autoComplete="off">
          <ModalBody>
            <Row>
              <Col className="col-sm-6">
                <FormikDatePicker autoFocus
                                  name="receivedOn"
                                  labelText="Date Received"/>
              </Col>
              <Col className="col-sm-6">
                <FormikNumberInput name="yearsUnderReview"
                                   labelText="Years Under Review"/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-end">
            <Button color="primary"
                    className="mr-2"
                    disabled={!formikProps.isValid || formikProps.isSubmitting || !formikProps.dirty}
                    onClick={formikProps.submitForm}>
              Save
            </Button>
            <Button color="secondary"
                    onClick={toggleOpen}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>)}
      </Formik>
    </Modal>
  </>;
};

export default QualifiedErrorFileInformationCard;