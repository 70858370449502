import {useState} from 'react';
import {Button, Card, CardHeader, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikCheckboxGroup, FormikInput, FormikNumberInput, FormikSelect, useUserContext} from '@reasoncorp/kyber-js';

import {QualifiedErrorResponse} from '../../types';
import {formatBoolean} from '../../util';
import {QualifiedErrorRequest} from '../../types/request';
import {qualifiedErrorReviewSchema} from '../../schema/qualifiedError';

type Props = {
  qualifiedError: QualifiedErrorResponse
  onSave: (qualifiedErrorRequest: QualifiedErrorRequest) => void
}

type Values = {
  completedRequestForPreQualifiedError?: boolean
  paymentReceived?: boolean
  letterReceived?: boolean
  ownershipVerified?: boolean
  occupancyVerified?: boolean
  signedAssessorStatement?: boolean
  grantRequest?: string
  grantRequestPartialYears?: number | string
  grantRequestReason?: string
  grantRequestExplanation?: string
}

const QualifiedErrorReviewCard = ({
                                    qualifiedError,
                                    onSave
                                  }: Props) => {
  const {permissions} = useUserContext();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleOpen = () => setModalIsOpen(!modalIsOpen);

  const handleSave = async (values: Values) => {
    const qualifiedErrorRequest = {
      ...qualifiedError,
      ...values
    } as QualifiedErrorRequest;
    await onSave(qualifiedErrorRequest);
    setModalIsOpen(false);
  };

  const initialValues = {
    completedRequestForPreQualifiedError: qualifiedError.completedRequestForPreQualifiedError,
    paymentReceived: qualifiedError.paymentReceived,
    letterReceived: qualifiedError.letterReceived,
    ownershipVerified: qualifiedError.ownershipVerified,
    occupancyVerified: qualifiedError.ownershipVerified,
    signedAssessorStatement: qualifiedError.signedAssessorStatement,
    grantRequest: qualifiedError.grantRequest,
    grantRequestPartialYears: qualifiedError.grantRequestPartialYears ?? '',
    grantRequestReason: qualifiedError.grantRequestReason,
    grantRequestExplanation: qualifiedError.grantRequestExplanation
  };

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            Review
          </Col>
          <Col sm="6" className="justify-content-end d-flex">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th className="w-16">PRE-Qualified Error Form</th>
            <th className="w-16">$150 Payment</th>
            <th className="w-16">Qualified Error Letter</th>
            <th className="w-16">Signed Assessor Statement</th>
            <th className="w-16">Ownership Verification</th>
            <th className="w-16">Occupancy Verification</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{formatBoolean(qualifiedError.completedRequestForPreQualifiedError)}&nbsp;</td>
            <td>{formatBoolean(qualifiedError.paymentReceived)}&nbsp;</td>
            <td>{formatBoolean(qualifiedError.letterReceived)}&nbsp;</td>
            <td>{formatBoolean(qualifiedError.signedAssessorStatement)}&nbsp;</td>
            <td>{formatBoolean(qualifiedError.ownershipVerified)}&nbsp;</td>
            <td>{formatBoolean(qualifiedError.occupancyVerified)}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
      <Table responsive bordered>
        <thead>
          <tr className="border-top-3">
            <th className="w-25">Grant Request</th>
            <th className="w-25">Years of Partial Grant</th>
            <th className="w-25">Reason</th>
            <th className="w-25">Explanation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{qualifiedError.grantRequest}&nbsp;</td>
            <td>{qualifiedError.grantRequestPartialYears}&nbsp;</td>
            <td>{qualifiedError.grantRequestReason}&nbsp;</td>
            <td>{qualifiedError.grantRequestExplanation}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={toggleOpen}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="xl">
      <ModalHeader toggle={toggleOpen}
                   tag="h2"
                   className="h5">
        Edit Review
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={qualifiedErrorReviewSchema}>
        {(formikProps) => (<Form onSubmit={formikProps.submitForm} autoComplete="off">
          <ModalBody>
            <Row>
              <Col className="col-sm-4">
                <FormikCheckboxGroup type="switch"
                                     checkboxes={[
                                       {
                                         name: 'completedRequestForPreQualifiedError',
                                         labelText: 'PRE-Qualified Error Form',
                                         autoFocus: true
                                       },
                                       {name: 'letterReceived', labelText: 'Qualified Error Letter'}
                                     ]}/>
              </Col>
              <Col className="col-sm-4">
                <FormikCheckboxGroup type="switch"
                                     checkboxes={[
                                       {name: 'paymentReceived', labelText: '$150 Payment'},
                                       {name: 'signedAssessorStatement', labelText: 'Signed Assessor Statement'}
                                     ]}/>
              </Col>
              <Col className="col-sm-4">
                <FormikCheckboxGroup type="switch"
                                     checkboxes={[
                                       {name: 'ownershipVerified', labelText: 'Ownership Verified'},
                                       {name: 'occupancyVerified', labelText: 'Occupancy Verified'}
                                     ]}/>
              </Col>
            </Row>
            <Row>
              <Col className="col-sm-3">
                <FormikSelect name="grantRequest"
                              labelText="Grant Request">
                  <option value="">Select</option>
                  <option value="YES">YES</option>
                  <option value="NO">NO</option>
                  <option value="PARTIAL">PARTIAL</option>
                </FormikSelect>
              </Col>
              <Col className="col-sm-6">
                <FormikSelect name="grantRequestReason"
                              labelText="Grant Request Reason"
                              disabled={formikProps.values.grantRequest !== 'NO' && formikProps.values.grantRequest !== 'PARTIAL'}>
                  <option value="">Select</option>
                  <option value="NOT A COMPLETE REQUEST">NOT A COMPLETE REQUEST</option>
                  <option value="NOT A QUALIFIED ERROR">NOT A QUALIFIED ERROR</option>
                  <option value="NOT AN OWNER">NOT AN OWNER</option>
                  <option value="DID NOT OCCUPY">DID NOT OCCUPY</option>
                </FormikSelect>
              </Col>
              <Col className="col-sm-3">
                <FormikNumberInput name="grantRequestPartialYears"
                                   disabled={formikProps.values.grantRequest !== 'PARTIAL'}
                                   labelText="Years of Partial Grant"/>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikInput name="grantRequestExplanation"
                             type="textarea"
                             formGroupClass="mb-0"
                             disabled={formikProps.values.grantRequest !== 'NO' && formikProps.values.grantRequest !== 'PARTIAL'}
                             labelText="Explanation"/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-end">
            <Button color="primary"
                    className="mr-2"
                    disabled={!formikProps.isValid || formikProps.isSubmitting || !formikProps.dirty}
                    onClick={formikProps.submitForm}>
              Save
            </Button>
            <Button color="secondary"
                    onClick={toggleOpen}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>)}
      </Formik>
    </Modal>
  </>;
};

export default QualifiedErrorReviewCard;