import {useContext, useState} from 'react';
import {Formik} from 'formik';
import {Button, Card, CardBody, CardHeader, Col, Container, Form, Row} from 'reactstrap';
import {Link} from 'react-router-dom';

import {
  CustomTable,
  FormikDatePicker,
  FormikInput,
  FormikSelect,
  ProgressIndicator,
  useAlerts
} from '@reasoncorp/kyber-js';

import {appealApi} from '../api';
import {AppealSearchResponse} from '../types';
import {AppealSearchRequest} from '../types/request';
import {formatDate} from '../util';
import * as messages from '../messages';
import {appealSearchSchema} from '../schema/appeal';
import {CollectionContext} from '../contexts';

const Appeals = () => {
  const [hasSearched, setHasSearched] = useState(false);
  const [appeals, setAppeals] = useState<AppealSearchResponse[]>([]);
  const {showErrorAlert} = useAlerts();
  const {collections} = useContext(CollectionContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async (appealSearchRequest: AppealSearchRequest) => {
    try {
      setIsLoading(true);
      const appeals = await appealApi.search(appealSearchRequest);
      setAppeals(appeals);
      setHasSearched(true);
      setIsLoading(false);
    } catch (e) {
      showErrorAlert(messages.API_FAILURE);
      setIsLoading(false);
    }
  };

  const handleReset = (resetForm: () => void) => {
    setAppeals([]);
    setHasSearched(false);
    resetForm();
  };

  const tableProps = {
    headers: [
      {title: 'Appeal ID', sortKey: 'fileNumber'},
      {title: 'Assigned To', sortKey: 'analystName'},
      {title: 'Status', sortKey: 'status'},
      {title: 'Appeal Received', sortKey: 'receivedOn'}
    ],
    renderRow: (appeal: AppealSearchResponse) => {
      return <tr key={appeal.id}>
        <td>
          <Link to={`/appeals/${appeal.id}`}>
            {appeal.fileNumber}
          </Link>
        </td>
        <td>{appeal.analystName}</td>
        <td>{appeal.statusDisplayValue}</td>
        <td>{formatDate(appeal.receivedOn)}</td>
      </tr>;
    },
    initialSort: {sortKey: 'denial.year', direction: 'desc' as const},
    items: appeals,
    paginatorConfig: {
      perPage: 100
    },
    noResultsMessage: 'No appeals found.'
  };

  const initialValues: AppealSearchRequest = {
    fileNumber: '',
    analystId: '',
    status: '',
    receivedOn: undefined
  };

  return <Formik initialValues={initialValues}
                 validateOnMount={true}
                 enableReinitialize={true}
                 onSubmit={handleSearch}
                 validationSchema={appealSearchSchema}>
    {(formikProps) => (
      <Form onSubmit={formikProps.handleSubmit}>
        <Container fluid>
          <Card className="mb-4">
            <CardHeader>
              Search Appeals
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="3">
                  <FormikInput name="fileNumber"
                               labelText="Appeal ID"/>
                </Col>
                <Col sm="3">
                  <FormikSelect name="analystId"
                                labelText="Assigned To">
                    <option value="">Select</option>
                    {collections?.analysts.map(analyst =>
                      <option key={analyst.id} value={analyst.id}>{analyst.fullName}</option>)}
                  </FormikSelect>
                </Col>
                <Col sm="3">
                  <FormikSelect name="status"
                                labelText="Status">
                    <option value="">Select</option>
                    {collections?.appealStatuses && Object.keys(collections?.appealStatuses).sort().map(value =>
                      <option value={value} key={value}>{collections.appealStatuses[value]}</option>)
                    }
                  </FormikSelect>
                </Col>
                <Col sm="3">
                  <FormikDatePicker name="receivedOn"
                                    labelText="Appeal Received"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-end">
                  <Button color="success"
                          size="sm"
                          type="submit"
                          disabled={formikProps.isSubmitting}
                          onClick={formikProps.submitForm}
                          className="mr-2">
                    Search
                  </Button>
                  <Button color="danger"
                          onClick={() => handleReset(formikProps.resetForm)}
                          size="sm">
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {isLoading && <ProgressIndicator className="mb-4"/>}
          {hasSearched && <Card>
            <CardHeader>Search Results</CardHeader>
            <CustomTable {...tableProps}/>
          </Card>}
        </Container>
      </Form>)}
  </Formik>;
};

export default Appeals;