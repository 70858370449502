import {useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikInput, FormikSelect, useUserContext, UsState} from '@reasoncorp/kyber-js';

import {BonaFideResponse} from '../../types';
import {BonaFideBillingInformationRequest, BonaFideRequest} from '../../types/request';
import {bonaFideBillingSchema} from '../../schema/bonaFide';

type Props = {
  bonaFide: BonaFideResponse
  onSave: (bonaFideRequest: BonaFideRequest) => void
}

const BonaFideBillingInformationCard = ({
                                          bonaFide,
                                          onSave
                                        }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {permissions} = useUserContext();

  const handleSave = async (billingInformation: BonaFideBillingInformationRequest) => {
    setModalIsOpen(false);
    const bonaFideRequest = {
      ...bonaFide,
      billingInformation
    } as BonaFideRequest;
    await onSave(bonaFideRequest);
  };

  const initialValues: BonaFideBillingInformationRequest = {
    billedPartyFirstName: bonaFide.billingInformation.billedPartyFirstName || '',
    billedPartyLastName: bonaFide.billingInformation.billedPartyLastName || '',
    billedCompanyName: bonaFide.billingInformation.billedCompanyName || '',
    mailingAddress: {
      address: bonaFide.billingInformation.mailingAddress.address,
      city: bonaFide.billingInformation.mailingAddress.city,
      state: bonaFide.billingInformation.mailingAddress.state,
      zip: bonaFide.billingInformation.mailingAddress.zip
    }
  };

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            Billing Information
          </Col>
          <Col sm="6" className="justify-content-end d-flex">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th className="w-33">Billed Party Name</th>
            <th className="w-33">Company Name</th>
            <th className="w-33">Mailing Address</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{bonaFide.billingInformation.billedPartyName}&nbsp;</td>
            <td>{bonaFide.billingInformation.billedCompanyName}&nbsp;</td>
            <td>{bonaFide.billingInformation.mailingAddress.fullDisplay}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="lg">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Billing Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={bonaFideBillingSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col sm="4">
                <FormikInput autoFocus
                             name="billedPartyFirstName"
                             labelText="First Name"/>
              </Col>
              <Col sm="4">
                <FormikInput name="billedPartyLastName"
                             labelText="Last Name"/>
              </Col>
              <Col sm="4">
                <FormikInput name="billedCompanyName"
                             labelText="Company Name"/>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikInput name="mailingAddress.address"
                             labelText="Mailing Street"/>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <FormikInput name="mailingAddress.city"
                             labelText="City"/>
              </Col>
              <Col sm="4">
                <FormikSelect name="mailingAddress.state"
                              labelText="State">
                  <option value="">Select</option>
                  {Object.keys(UsState).map(state =>
                    <option value={state} key={state}>{state}</option>)}
                </FormikSelect>
              </Col>
              <Col sm="4">
                <FormikInput name="mailingAddress.zip"
                             labelText="Zip"/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row className="justify-content-end">
              <Col>
                <Button color="success" className="mr-2"
                        disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
                        onClick={formikProps.submitForm}>
                  Save
                </Button>
                <Button color="secondary"
                        onClick={() => setModalIsOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default BonaFideBillingInformationCard;