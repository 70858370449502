import {ChangeEvent, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Modal, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik, FormikProps} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ButtonIcon, FormikNumberInput, FormikSelect, useUserContext} from '@reasoncorp/kyber-js';

import {DenialResponse} from '../../types';
import {DenialRequest, YearDeniedRequest} from '../../types/request';
import {yearsDeniedSchema} from '../../schema/denial';
import {availableDenialYears, formatPercent} from '../../util';

type Props = {
  denial: DenialResponse
  onSave: (denialRequest: DenialRequest) => void
}

type YearDeniedRequests = {
  yearsDenied: YearDeniedRequest[]
  uniqueYearsErrorMessage?: string // can be added by validation schema
};

const DenialYearsDeniedCard = ({
                                 denial,
                                 onSave
                               }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hasRemovedYear, setHasRemovedYear] = useState(false);
  const {permissions} = useUserContext();

  const handleSave = async (yearsDeniedRequest: YearDeniedRequests) => {
    setModalIsOpen(false);
    const denialRequest = {
      ...denial,
      yearsDenied: yearsDeniedRequest.yearsDenied
    } as DenialRequest;
    await onSave(denialRequest);
  };

  const initialValues: YearDeniedRequests = {
    yearsDenied: [...denial.yearsDenied]
  };

  const handleAddYear = (formikProps: FormikProps<YearDeniedRequests>) => {
    const newYear = {
      year: '',
      proposed: '',
      allowedPercent: ''
    } as YearDeniedRequest;
    formikProps.setFieldValue('yearsDenied',
      [formikProps.values.yearsDenied, ...[newYear]].flat()
    );
  };

  const handleDecisionChange = (formikProps: FormikProps<YearDeniedRequests>, index: number) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === 'VALID') {
        formikProps.setFieldValue(`yearsDenied[${index}].allowedPercent`, 100);
      } else if (e.target.value === 'DENY') {
        formikProps.setFieldValue(`yearsDenied[${index}].allowedPercent`, '');
      }
    };

  const handleRemoveYear = (formikProps: FormikProps<YearDeniedRequests>, index: number) => {
    const yearsDenied = [...formikProps.values.yearsDenied];
    yearsDenied.splice(index, 1);
    formikProps.setFieldValue('yearsDenied', yearsDenied);
    setHasRemovedYear(true);
  };

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            Denial Recommendations By Year
          </Col>
          <Col sm="6" className="justify-content-end d-flex">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   size="sm"
                                                   onClick={() => setModalIsOpen(true)}>
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      {denial.yearsDenied.length === 0 && <CardBody>
        No years denied.
      </CardBody>}
      <Table responsive bordered>
        <thead>
          <tr>
            {denial.yearsDenied.map(yearDenied =>
              <th className="w-25" key={yearDenied.year}>
                {yearDenied.year} {yearDenied.proposed === 'DENY' &&
              <span className="float-right">% Allowed</span>}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            {denial.yearsDenied.map(yearDenied =>
              <td key={yearDenied.year}>
                {yearDenied.proposed} {yearDenied.proposed === 'DENY' &&
              <span className="float-right">{formatPercent(yearDenied.allowedPercent)}</span>}
              </td>
            )}
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="lg">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5 mb-0">
        Edit Denial Recommendations By Year
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={yearsDeniedSchema}>
        {(formikProps) => (<>
          <Table responsive bordered striped>
            <thead>
              <tr>
                <th className="w-20">Year</th>
                <th className="w-50">Decision</th>
                <th className="w-25 text-right">Percent Allowed</th>
                <th className="w-10"/>
              </tr>
            </thead>
            <tbody>
              {formikProps.values.yearsDenied.length === 0 && <tr>
                <td colSpan={4}>No years denied added yet.</td>
              </tr>}
              {formikProps.values.yearsDenied.map((_, index) =>
                <tr key={index}>
                  <th scope="row" className="align-middle">
                    <FormikSelect autoFocus={index === 0}
                                  name={`yearsDenied[${index}].year`}
                                  formGroupClass="mb-0"
                                  ariaLabel="Year">
                      <option value="">Select</option>
                      {availableDenialYears.map(year => <option value={year} key={year}>{year}</option>)}
                    </FormikSelect>
                  </th>
                  <td>
                    <FormikSelect name={`yearsDenied[${index}].proposed`}
                                  formGroupClass="mb-0"
                                  onChange={handleDecisionChange(formikProps, index)}
                                  ariaLabel="Decision">
                      <option value="">Select</option>
                      <option value="VALID" key="VALID">VALID</option>
                      <option value="DENY" key="DENY">DENY</option>
                    </FormikSelect>
                  </td>
                  <td>
                    <FormikNumberInput name={`yearsDenied[${index}].allowedPercent`}
                                       formGroupClass="mb-0"
                                       className="text-right"
                                       disableFloatingLabel
                                       maxLength={3}
                                       disabled={formikProps.values.yearsDenied[index].proposed === 'VALID'}
                                       ariaLabel="Percent Allowed"
                    />
                  </td>
                  <td className="align-middle text-center">
                    <ButtonIcon icon="trash"
                                onClick={() => handleRemoveYear(formikProps, index)}
                                className="text-danger"
                                title={`Delete Year ${formikProps.values.yearsDenied[index].year}`}
                                ariaLabel={`Delete Year ${formikProps.values.yearsDenied[index].year}`}/>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {formikProps.errors.uniqueYearsErrorMessage &&
          <Row className="ml-1 mr-1 pt-2 pb-2">
            <Col className="text-danger font-weight-bold">
              {formikProps.errors.uniqueYearsErrorMessage}
            </Col>
          </Row>
          }
          <ModalFooter className="pr-0 pl-0">
            <Row className="w-100 d-flex m-0">
              <Col className="align-self-center" sm="4">
                <Button size="sm"
                        outline
                        disabled={formikProps.values.yearsDenied.length >= 4}
                        onClick={() => handleAddYear(formikProps)}
                        color="primary">
                  <FontAwesomeIcon icon="plus-circle" className="mr-2"/>
                  Add Year
                </Button>
              </Col>
              <Col className="d-flex justify-content-end" sm="8">
                <Button color="success"
                        className="mr-2"
                        disabled={formikProps.isSubmitting || (!hasRemovedYear && !formikProps.dirty) || !formikProps.isValid}
                        onClick={formikProps.submitForm}>
                  Save
                </Button>
                <Button color="secondary"
                        onClick={() => setModalIsOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default DenialYearsDeniedCard;