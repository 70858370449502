import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {
  AlertsProvider,
  configureRaygun,
  ProSuiteJurisdiction,
  ProSuiteRole,
  ssoUtils,
  User,
  UserProvider
} from '@reasoncorp/kyber-js';

import {version} from '../package.json';
import './index.scss';
import config from './config';
import {ssoApi} from './api';
import App from './App';
import {CollectionProvider} from './contexts';
import * as messages from './messages';

if (config.envName !== 'prod') {
  document.title = `${config.envName.toUpperCase()} - ${messages.APP_TITLE}`;
}

const raygunOptions = {
  apiKey: process.env.REACT_APP_RAYGUN_API_KEY || 'apikeygoeshere',
  version: version,
  tags: [config.envName]
};

configureRaygun(raygunOptions);

const setPermissions = (currentUser?: User) => ({
  hasImportAndLetterAccess: ssoUtils.hasProSuiteAdminRole(currentUser, ProSuiteJurisdiction.PROSERV_APP) ||
    ssoUtils.hasJurisdictionAndRole(currentUser, ProSuiteJurisdiction.PROSERV_APP, ProSuiteRole.ANALYST) ||
    ssoUtils.hasJurisdictionAndRole(currentUser, ProSuiteJurisdiction.PROSERV_APP, ProSuiteRole.SUPERVISOR),
  hasWriteAccess: ssoUtils.hasProSuiteAdminRole(currentUser, ProSuiteJurisdiction.PROSERV_APP) ||
    ssoUtils.hasJurisdictionAndRole(currentUser, ProSuiteJurisdiction.PROSERV_APP, ProSuiteRole.TECHNICIAN) ||
    ssoUtils.hasJurisdictionAndRole(currentUser, ProSuiteJurisdiction.PROSERV_APP, ProSuiteRole.ANALYST) ||
    ssoUtils.hasJurisdictionAndRole(currentUser, ProSuiteJurisdiction.PROSERV_APP, ProSuiteRole.SUPERVISOR),
  hasAdminAccess: ssoUtils.hasProSuiteAdminRole(currentUser, ProSuiteJurisdiction.PROSERV_APP)
});

if (window.navigator.msSaveOrOpenBlob !== undefined) {
  window.location.href = config.sso.webUrl + '/browser-not-supported.html';
} else {
  const root = createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <BrowserRouter>
      <UserProvider ssoApi={ssoApi} setPermissions={setPermissions}>
        <AlertsProvider>
          <CollectionProvider>
            <App/>
          </CollectionProvider>
        </AlertsProvider>
      </UserProvider>
    </BrowserRouter>
  );
}