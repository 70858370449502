import {useEffect, useState} from 'react';
import {Button, Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {Link, useNavigate, useParams} from 'react-router-dom';

import {
  ButtonIcon,
  ConfirmationModal,
  CustomTable,
  downloadUtils,
  ProgressIndicator,
  useAlerts
} from '@reasoncorp/kyber-js';

import {DenialNavBar} from '../components/denials';
import {DenialLetter, DenialLetterBatch} from '../types';
import {denialLetterApi} from '../api';
import * as messages from '../messages';
import {formatDate} from '../util';
import {LetterBatchDeleteModal} from '../components/letterBatches';

const LetterBatch = () => {
  const id = Number(useParams<{id: string}>().id);
  const [denialLetterBatch, setDenialLetterBatch] = useState<DenialLetterBatch | undefined>(undefined);
  const [selectedDenialLetter, setSelectedDenialLetter] = useState<DenialLetter | undefined>(undefined);
  const [deleteLetterBatchModalIsOpen, setDeleteLetterBatchModalIsOpen] = useState(false);
  const [deleteLetterModalIsOpen, setDeleteLetterModalIsOpen] = useState(false);
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: false, loadError: false, processing: false});
  const navigate = useNavigate();

  useEffect(() => {
    const loadDenialLetterBatch = async () => {
      try {
        setLoadingState({loading: true, loadError: false, processing: false});
        const denialLetterBatch = await denialLetterApi.findBatch(id);
        setDenialLetterBatch(denialLetterBatch);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE);
        setLoadingState({loading: false, loadError: true, processing: false});
      }
    };
    loadDenialLetterBatch().then();
  }, [id, showErrorAlert]);

  const handleDeleteLetter = async (letterId: number) => {
    try {
      setLoadingState({loading: false, loadError: false, processing: true});
      await denialLetterApi.deleteLetter(letterId);
      const denialLetterBatch = await denialLetterApi.findBatch(id);
      setDenialLetterBatch(denialLetterBatch);

      if (denialLetterBatch.expectedItemCount === 0) {
        navigate('/letters/batches');
      }

      showSuccessAlert(messages.LETTER_DELETE_SUCCESSFUL);
      setLoadingState({loading: false, loadError: false, processing: false});
    } catch (e) {
      showErrorAlert(messages.LETTER_DELETE_FAILURE);
      setLoadingState({loading: false, loadError: false, processing: false});
    }
    setDeleteLetterModalIsOpen(false);
  };

  const handleDeleteLetterBatch = async (id: number) => {
    setLoadingState({...loadingState, processing: true});
    try {
      await denialLetterApi.deleteBatch(id);
      navigate('/letters/batches');
      showSuccessAlert(messages.LETTER_BATCH_DELETE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.LETTER_BATCH_DELETE_FAILURE);
    }

    setLoadingState({...loadingState, processing: false});
  };

  const handleDownloadLetter = async (id: number) => {
    try {
      await downloadUtils.downloadFile(denialLetterApi.getSignedUrl(id));
    } catch (e) {
      showErrorAlert(messages.LETTER_DOWNLOAD_FAILURE);
    }
  };

  const handleRecreateLetter = async (letterId: number) => {
    try {
      setLoadingState({loading: false, loadError: false, processing: true});
      await denialLetterApi.recreate(letterId);
      const denialLetterBatch = await denialLetterApi.findBatch(id);
      setDenialLetterBatch(denialLetterBatch);
      showSuccessAlert(messages.LETTER_RECREATE_SUCCESSFUL);
      setLoadingState({loading: false, loadError: false, processing: false});
    } catch (e) {
      showErrorAlert(messages.LETTER_RECREATE_FAILURE);
      setLoadingState({loading: false, loadError: false, processing: false});
    }
  };

  const tableProps = {
    headers: [
      {title: 'Year', className: 'text-center'},
      {title: 'Parcel ID', className: 'text-center'},
      {title: 'Updated ', className: 'text-center'},
      {title: 'Denial', className: 'text-center'},
      {title: 'Letter', className: 'text-center'},
      {title: 'Recreate', className: 'text-center'},
      {title: 'Remove', className: 'text-center'}
    ],
    items: denialLetterBatch?.denialLetters ?? [],
    renderRow: (denialLetter: DenialLetter) => {
      return <tr key={denialLetter.id}>
        <td className="text-center align-middle">{denialLetter.year}</td>
        <td className="text-center align-middle">{denialLetter.parcelNumber}</td>
        <td className="text-center align-middle">{formatDate(denialLetter.updatedAt)}</td>
        <td className="text-center align-middle">
          <Link to={`/denials/${denialLetter.denialId}`}>
            {denialLetter.fileNumber}
          </Link>
        </td>
        <td className="text-center  align-middle">
          <ButtonIcon icon="file-pdf"
                      disabled={loadingState.processing || !denialLetter.active}
                      title={`Download Denial ${denialLetter.fileNumber} Letter`}
                      ariaLabel={`Download Denial ${denialLetter.fileNumber} Letter`}
                      onClick={() => handleDownloadLetter(denialLetter.id)}
          />
        </td>
        <td className="text-center text-success align-middle">
          <ButtonIcon icon="redo"
                      disabled={loadingState.processing || !denialLetter.active}
                      title={`Recreate Denial ${denialLetter.fileNumber} Letter`}
                      ariaLabel={`Recreate Denial ${denialLetter.fileNumber} Letter`}
                      onClick={() => handleRecreateLetter(denialLetter.id)}/>
        </td>
        <td className="text-center text-danger align-middle">
          <ButtonIcon icon="trash"
                      disabled={loadingState.processing || !denialLetter.active}
                      title={`Delete Denial ${denialLetter.fileNumber} Letter`}
                      ariaLabel={`Delete Denial ${denialLetter.fileNumber} Letter`}
                      onClick={() => {
                        setSelectedDenialLetter(denialLetter);
                        setDeleteLetterModalIsOpen(true);
                      }}/>
        </td>
      </tr>;
    },
    paginatorConfig: {
      perPage: 100
    }
  };

  return <Container fluid>
    <Row className="mb-4">
      <Col className="d-flex justify-content-end">
        <DenialNavBar/>
      </Col>
    </Row>
    {loadingState.loading && <ProgressIndicator/>}
    {denialLetterBatch && !loadingState.loadError && !loadingState.loading && <>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col sm="6" className="align-self-center">
                  Denial Letter Batch {denialLetterBatch.fileNumber}
                </Col>
                <Col sm="6" className="d-flex justify-content-end">
                  <Button color="primary"
                          onClick={() => setDeleteLetterBatchModalIsOpen(true)}
                          size="sm">
                    Delete Batch
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CustomTable {...tableProps}/>
          </Card>
        </Col>
      </Row>
      <LetterBatchDeleteModal denialLetterBatch={denialLetterBatch}
                              isOpen={deleteLetterBatchModalIsOpen}
                              processing={loadingState.processing}
                              onDelete={handleDeleteLetterBatch}
                              onCancel={() => setDeleteLetterBatchModalIsOpen(false)}/>

      {selectedDenialLetter && <ConfirmationModal isOpen={deleteLetterModalIsOpen}
                                                  title="Delete Letter"
                                                  size="lg"
                                                  confirmButtonText="Yes"
                                                  cancelButtonText="No"
                                                  confirmButtonDisabled={loadingState.processing}
                                                  cancelButtonDisabled={loadingState.processing}
                                                  confirmCallback={() => handleDeleteLetter(selectedDenialLetter.id)}
                                                  cancelCallback={() => setDeleteLetterModalIsOpen(false)}>
        Are you sure you wish to delete the letter for denial <span className="text-danger">{selectedDenialLetter.fileNumber}</span>?
      </ConfirmationModal>}
    </>}
  </Container>;
};

export default LetterBatch;