import {useState} from 'react';
import {Link} from 'react-router-dom';
import {Formik} from 'formik';
import {Button, Card, CardBody, CardHeader, Col, Container, Form, Row} from 'reactstrap';

import {
  CustomTable,
  FormikDatePicker,
  FormikInput,
  FormikSelect,
  ProgressIndicator,
  useAlerts,
  useUserContext
} from '@reasoncorp/kyber-js';

import {BonaFideSearchResponse} from '../types';
import {BonaFideSearchRequest} from '../types/request';
import {formatBoolean, formatDate} from '../util';
import {bonaFideApi} from '../api';
import {BonaFideCreateModal} from '../components/bonaFides';
import * as messages from '../messages';
import {bonaFideSearchSchema} from '../schema/bonaFide';

const BonaFides = () => {
  const [hasSearched, setHasSearched] = useState(false);
  const [bonaFides, setBonaFides] = useState<BonaFideSearchResponse[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {showErrorAlert} = useAlerts();
  const [isLoading, setIsLoading] = useState(false);
  const {permissions} = useUserContext();

  const handleSearch = async (bonaFideSearchRequest: BonaFideSearchRequest) => {
    try {
      setIsLoading(true);
      const bonaFides = await bonaFideApi.search(bonaFideSearchRequest);
      setBonaFides(bonaFides);
      setHasSearched(true);
      setIsLoading(false);
    } catch (e) {
      showErrorAlert(messages.API_FAILURE);
      setIsLoading(false);
    }
  };

  const tableProps = {
    headers: [
      {title: 'File Number', sortKey: 'fileNumber'},
      {title: 'Parcel ID', sortKey: 'parcel.parcelNumber'},
      {title: 'Parcel Address', sortKey: 'parcel.address.fullDisplay'},
      {title: 'Billing Name', sortKey: 'billedPartyName'},
      {title: 'Bill Sent', sortKey: 'issueBill'},
      {title: 'Date Received', sortKey: 'receivedOn'}
    ],
    renderRow: (bonaFide: BonaFideSearchResponse) => {
      return <tr key={bonaFide.id}>
        <td>
          <Link to={`/bona-fides/${bonaFide.id}`}>
            {bonaFide.fileNumber}
          </Link>
        </td>
        <td>{bonaFide.parcel.parcelNumber}</td>
        <td>{bonaFide.parcel.address.fullDisplay}</td>
        <td>{bonaFide.billedPartyName}</td>
        <td>{formatBoolean(bonaFide.issueBill)}</td>
        <td>{formatDate(bonaFide.receivedOn)}</td>
      </tr>;
    },
    items: bonaFides,
    initialSort: {sortKey: 'year', direction: 'desc' as const},
    paginatorConfig: {
      perPage: 100
    },
    noResultsMessage: 'No bona fides found.'

  };

  const handleReset = (resetForm: () => void) => {
    setBonaFides([]);
    setHasSearched(false);
    resetForm();
  };

  const initialValues: BonaFideSearchRequest = {
    fileNumber: '',
    parcelAddress: '',
    parcelNumber: '',
    billedPartyName: '',
    issueBill: '',
    receivedOn: undefined
  };

  return <Container fluid>
    {permissions.hasWriteAccess && <Row className="mb-4">
      <Col className="d-flex justify-content-end">
        <Button
          onClick={() => setModalIsOpen(true)}
          color="primary">
          Create Bona Fide
        </Button>
      </Col>
    </Row>}
    <Formik initialValues={initialValues}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={handleSearch}
            validationSchema={bonaFideSearchSchema}>
      {(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <Card className="mb-4">
            <CardHeader>
              Search Bona Fides
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="2">
                  <FormikInput name="fileNumber"
                               labelText="File Number"/>
                </Col>

                <Col sm="2">
                  <FormikInput name="parcelNumber"
                               labelText="Parcel ID"/>
                </Col>
                <Col sm="2">
                  <FormikInput name="parcelAddress"
                               labelText="Parcel Address"/>
                </Col>

                <Col sm="2">
                  <FormikInput name="billedPartyName"
                               labelText="Billing Name"/>
                </Col>

                <Col sm="2">
                  <FormikSelect name="issueBill"
                                labelText="Bill Sent">
                    <option value="">Select</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </FormikSelect>
                </Col>
                <Col sm="2">
                  <FormikDatePicker name="receivedOn"
                                    labelText="Date Received"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-end">
                  <Button color="success"
                          size="sm"
                          type="submit"
                          disabled={formikProps.isSubmitting}
                          onClick={formikProps.submitForm}
                          className="mr-2">
                    Search
                  </Button>
                  <Button color="danger"
                          onClick={() => handleReset(formikProps.resetForm)}
                          size="sm">
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {isLoading && <ProgressIndicator className="mb-4"/>}
          {hasSearched && <Card>
            <CardHeader>Search Results</CardHeader>
            <CustomTable {...tableProps}/>
          </Card>}
        </Form>)}
    </Formik>
    <BonaFideCreateModal isOpen={modalIsOpen}
                         toggleOpen={() => setModalIsOpen(!modalIsOpen)}/>
  </Container>;
};

export default BonaFides;