import {useEffect, useState} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import {useParams} from 'react-router-dom';

import {downloadUtils, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import * as messages from '../messages';
import {Address, Call, DocumentResponse, InterestWaiverResponse, Note} from '../types';
import {InterestWaiverRequest, ParcelRequest} from '../types/request';
import {callApi, documentApi, interestWaiverApi, noteApi} from '../api';
import {
  CallsCard,
  DocumentsCard,
  NotesCard,
  ParcelCard,
  RecordNavBar,
  RelatedParcelRecordsCard,
  SimpleMailingAddressCard
} from '../components/shared';
import {
  InterestWaiverAnalystRecommendationCard,
  InterestWaiverAssessorInformationCard,
  InterestWaiverDenialInformationCard,
  InterestWaiverErrorInformationCard,
  InterestWaiverFileInformationCard,
  InterestWaiverOwnerCard,
  InterestWaiverSupervisorDeterminationCard
} from '../components/interestWaivers';

const InterestWaiver = () => {
  const id = Number(useParams<{id: string}>().id);
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [interestWaiver, setInterestWaiver] = useState<InterestWaiverResponse | undefined>(undefined);
  const [loadingState, setLoadingState] = useState({loading: false, loadError: false, processing: false});
  const [notes, setNotes] = useState<Note[]>([]);
  const [calls, setCalls] = useState<Call[]>([]);
  const [documents, setDocuments] = useState<DocumentResponse[]>([]);

  useEffect(() => {
    const loadInterestWaiver = async () => {
      setLoadingState({loading: true, loadError: false, processing: false});
      try {
        const [interestWaiver, documents] = await Promise.all([
          interestWaiverApi.find(id),
          interestWaiverApi.findDocuments(id)
        ]);
        setInterestWaiver(interestWaiver);
        setDocuments(documents);
        const [notes, calls] = await Promise.all([
          noteApi.findAll(interestWaiver.parcel.id),
          callApi.findAll(interestWaiver.parcel.id)
        ]);
        setNotes(notes);
        setCalls(calls);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE);
        setLoadingState({loading: false, loadError: true, processing: false});
      }
    };

    loadInterestWaiver().then();
  }, [id, showErrorAlert]);

  const loadInterestWaiver = async () => {
    try {
      const [interestWaiver, documents] = await Promise.all([
        interestWaiverApi.find(id),
        interestWaiverApi.findDocuments(id)
      ]);
      setInterestWaiver(interestWaiver);
      setDocuments(documents);
      const [notes, calls] = await Promise.all([
        noteApi.findAll(interestWaiver.parcel.id),
        callApi.findAll(interestWaiver.parcel.id)
      ]);
      setNotes(notes);
      setCalls(calls);
    } catch (e) {
      showErrorAlert(messages.API_FAILURE);
      setLoadingState({loading: false, loadError: true, processing: false});
    }
  };

  const handleSave = async (interestWaiverRequest: InterestWaiverRequest) => {
    try {
      setLoadingState({loading: false, loadError: false, processing: true});
      const interestWaiver = await interestWaiverApi.save(id, interestWaiverRequest);
      setInterestWaiver(interestWaiver);
      showSuccessAlert(messages.INTEREST_WAIVER_SAVE_SUCCESSFUL);
      setLoadingState({loading: false, loadError: false, processing: false});
    } catch (e) {
      showErrorAlert(messages.INTEREST_WAIVER_SAVE_FAILURE);
      setLoadingState({loading: false, loadError: true, processing: false});
    }
  };

  const handleDownloadPdf = async () => {
    try {
      setLoadingState({loading: false, loadError: false, processing: true});
      await interestWaiverApi.download(id);
    } catch (e) {
      showErrorAlert(messages.DOCUMENT_DOWNLOAD_FAILURE);
    }
    setLoadingState({loading: false, loadError: false, processing: false});
  };

  const handleParcelSave = (parcel: ParcelRequest) => handleSave({
    ...interestWaiver,
    parcel
  } as InterestWaiverRequest);

  const handleMailingAddressSave = (mailingAddress: Address) => handleSave({
    ...interestWaiver,
    mailingAddress
  } as InterestWaiverRequest);

  const handleDownloadFile = async (path: string) => {
    try {
      await downloadUtils.downloadFile(documentApi.getSignedUrl(path));
    } catch (e) {
      showErrorAlert(messages.DOCUMENT_DOWNLOAD_FAILURE);
    }
  };

  const handleSaveAnalystRecommendation = async (formData: FormData) => {
    try {
      await interestWaiverApi.saveAnalystRecommendation(id, formData);
      const [interestWaiver, documents] = await Promise.all([
        interestWaiverApi.find(id),
        interestWaiverApi.findDocuments(id)
      ]);
      setInterestWaiver(interestWaiver);
      setDocuments(documents);
      showSuccessAlert(messages.INTEREST_WAIVER_SAVE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.INTEREST_WAIVER_SAVE_FAILURE);
    }
  };

  const handleSaveSupervisorDetermination = async (formData: FormData) => {
    try {
      await interestWaiverApi.saveSupervisorDetermination(id, formData);
      const [interestWaiver, documents] = await Promise.all([
        interestWaiverApi.find(id),
        interestWaiverApi.findDocuments(id)
      ]);
      setInterestWaiver(interestWaiver);
      setDocuments(documents);
      showSuccessAlert(messages.INTEREST_WAIVER_SAVE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.INTEREST_WAIVER_SAVE_FAILURE);
    }
  };

  const renderButtons = () => <>
    <Button color="primary"
            disabled={loadingState.processing}
            onClick={async () => await handleDownloadPdf()}
            className="mr-2">
      View PDF
    </Button>
  </>;

  return <Container fluid>
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loadError && !loadingState.loading && interestWaiver && <>
      <Row className="mb-4">
        <Col className="align-self-center" sm="3">
          <h1 className="h5 mb-0">Interest Waiver: {interestWaiver.fileNumber}</h1>
        </Col>
        <Col className="d-flex justify-content-end" sm="9">
          <RecordNavBar parcel={interestWaiver.parcel}
                        denialId={interestWaiver.denialId}
                        bonaFideId={interestWaiver.bonaFideId}
                        interestWaiverId={interestWaiver.id}
                        currentRecordType="INTEREST_WAIVER"
                        createButtonsDisabled={interestWaiver.year < new Date().getFullYear() - 5}
                        onRecordSave={loadInterestWaiver}
                        renderButtons={renderButtons}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <InterestWaiverFileInformationCard interestWaiver={interestWaiver}
                                             onSave={handleSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <ParcelCard parcel={interestWaiver.parcel}
                      onSave={handleParcelSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm="12">
          <InterestWaiverOwnerCard interestWaiver={interestWaiver}
                                   onSave={handleSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm="6">
          <SimpleMailingAddressCard mailingAddress={interestWaiver.mailingAddress}
                                    onSave={handleMailingAddressSave}/>
        </Col>
        <Col sm="6">
          <InterestWaiverDenialInformationCard interestWaiver={interestWaiver}
                                               onSave={handleSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm="6">
          <InterestWaiverAssessorInformationCard interestWaiver={interestWaiver}
                                                 onSave={handleSave}/>
        </Col>
        <Col sm="6">
          <InterestWaiverErrorInformationCard interestWaiver={interestWaiver}
                                              onSave={handleSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm="6">
          <InterestWaiverAnalystRecommendationCard interestWaiver={interestWaiver}
                                                   onSave={handleSaveAnalystRecommendation}
                                                   onDownloadFile={handleDownloadFile}/>
        </Col>
        <Col sm="6">
          <InterestWaiverSupervisorDeterminationCard interestWaiver={interestWaiver}
                                                     onSave={handleSaveSupervisorDetermination}
                                                     onDownloadFile={handleDownloadFile}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm="6">
          <RelatedParcelRecordsCard parcelId={interestWaiver.parcel.id}/>
        </Col>
      </Row>
      <NotesCard notes={notes}
                 parcelId={interestWaiver.parcel.id}
                 onSaveSuccess={loadInterestWaiver}/>
      <CallsCard calls={calls}
                 parcelId={interestWaiver.parcel.id}
                 onSaveSuccess={loadInterestWaiver}/>
      <DocumentsCard documents={documents}
                     parcelId={interestWaiver.parcel.id}
                     onSuccess={loadInterestWaiver}/>
    </>}
  </Container>;
};

export default InterestWaiver;