import {useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik, FormikProps} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ButtonIcon, FormikDecimalInput, FormikNumberInput, FormikSelect, useUserContext} from '@reasoncorp/kyber-js';

import {availableBonaFideDenialYears, formatDecimal, formatMoney, sum} from '../../util';
import {BonaFideDenialYear, BonaFideResponse} from '../../types';
import {BonaFideRequest} from '../../types/request';
import {bonaFideDenialYearsSchema} from '../../schema/bonaFide';

type Props = {
  bonaFide: BonaFideResponse
  onSave: (bonaFideRequest: BonaFideRequest) => void
}

type BonaFideDenialYearRequests = {
  denialYears: BonaFideDenialYear[]
  uniqueYearsErrorMessage?: string // can be added via validation schema
};

const BonaFideDenialYearCard = ({
                                  bonaFide,
                                  onSave
                                }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hasRemovedYear, setHasRemovedYear] = useState(false);
  const {permissions} = useUserContext();

  const initialValues: BonaFideDenialYearRequests = {
    denialYears: bonaFide.denialYears
  };

  const handleSave = async (bonaFideDenialYearRequests: BonaFideDenialYearRequests) => {
    setModalIsOpen(false);
    const bonaFideRequest = {
      ...bonaFide,
      denialYears: bonaFideDenialYearRequests.denialYears
    } as BonaFideRequest;
    await onSave(bonaFideRequest);
  };

  const handleAddYear = (formikProps: FormikProps<BonaFideDenialYearRequests>) => {
    const newYear = {
      year: availableBonaFideDenialYears[0],
      taxableValue: '',
      amountBilledSchoolOperating: '',
      taxDue: '',
      interestDue: '',
      season: ''
    } as BonaFideDenialYear;
    formikProps.setFieldValue('denialYears',
      [formikProps.values.denialYears, ...[newYear]].flat()
    );
  };

  const handleRemoveYear = (formikProps: FormikProps<BonaFideDenialYearRequests>, index: number) => {
    const denialYears = [...formikProps.values.denialYears];
    denialYears.splice(index, 1);
    formikProps.setFieldValue('denialYears', denialYears);
    setHasRemovedYear(true);
  };

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            Denial and Interest Year(s) Information
          </Col>
          <Col sm="6" className="justify-content-end d-flex">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>Denial Year</th>
            <th>School Operating Millage Rate for Denial Year</th>
            <th>Taxable Value for Denial Year</th>
            <th>Due Date</th>
            <th>Tax Due</th>
            <th>Interest Due</th>
            <th>Total Due</th>
          </tr>
        </thead>
        <tbody>
          {bonaFide.denialYears.length === 0 && <tr>
            <td colSpan={7}>
              No denial years.
            </td>
          </tr>}
          {bonaFide.denialYears.map((denialYear, index) =>
            <tr key={index}>
              <td>{denialYear.year}</td>
              <td>{formatDecimal(denialYear.amountBilledSchoolOperating)}</td>
              <td>{formatMoney(denialYear.taxableValue)}</td>
              <td>{denialYear.season}</td>
              <td>{formatMoney(denialYear.taxDue)}</td>
              <td>{formatMoney(denialYear.interestDue)}</td>
              <td>{formatMoney(sum([denialYear.taxDue, denialYear.interestDue]))}</td>
            </tr>)}
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="xl">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5 mb-0">
        Edit Denial and Interest Year(s) Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={bonaFideDenialYearsSchema}>
        {(formikProps) => (<>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th className="text-center">Denial Year</th>
                <th>School Operating Millage Rate for Denial Year</th>
                <th>Taxable Value for Denial Year</th>
                <th>Due Date</th>
                <th>Tax Due</th>
                <th>Interest Due</th>
                <th>Total Due</th>
                <th/>
              </tr>
            </thead>
            <tbody>
              {formikProps.values.denialYears.length === 0 && <tr>
                <td colSpan={8}>No denial years added yet.</td>
              </tr>}
              {formikProps.values.denialYears.map((denialYear, index) =>
                <tr key={index}>
                  <td>
                    <FormikSelect autoFocus={index === 0}
                                  name={`denialYears[${index}].year`}
                                  ariaLabel="Due Date">
                      {availableBonaFideDenialYears.map(year => <option value={year} key={year}>{year}</option>)}
                    </FormikSelect>
                  </td>
                  <td>
                    <FormikDecimalInput name={`denialYears[${index}].amountBilledSchoolOperating`}
                                        decimalPlaces="4"
                                        integerPlaces="2"
                                        formGroupClass="mb-0"
                                        disableFloatingLabel={true}
                                        ariaLabel="Millage Rate"/>
                  </td>
                  <td>
                    <FormikNumberInput name={`denialYears[${index}].taxableValue`}
                                       formGroupClass="mb-0"
                                       disableFloatingLabel={true}
                                       ariaLabel="Taxable Value"/></td>
                  <td>
                    <FormikSelect name={`denialYears[${index}].season`}
                                  ariaLabel="Due Date">
                      <option key="" value="">Select</option>
                      <option key="SUMMER" value="SUMMER">SUMMER</option>
                      <option key="WINTER" value="WINTER">WINTER</option>
                      <option key="COMBINED" value="COMBINED">COMBINED</option>
                    </FormikSelect>
                  </td>
                  <td>
                    <FormikNumberInput name={`denialYears[${index}].taxDue`}
                                       formGroupClass="mb-0"
                                       disableFloatingLabel={true}
                                       ariaLabel="Tax Due"/>
                  </td>
                  <td>
                    <FormikNumberInput name={`denialYears[${index}].interestDue`}
                                       formGroupClass="mb-0"
                                       disableFloatingLabel={true}
                                       ariaLabel="Interest Due"/>
                  </td>
                  <td className="align-middle">
                    {formatMoney(sum([denialYear.taxDue, denialYear.interestDue]))}
                  </td>
                  <td className="align-middle text-center">
                    <ButtonIcon icon="trash"
                                onClick={() => handleRemoveYear(formikProps, index)}
                                className="text-danger"
                                title={`Delete Year ${formikProps.values.denialYears[index].year}`}
                                ariaLabel={`Delete Year ${formikProps.values.denialYears[index].year}`}/>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {formikProps.errors.uniqueYearsErrorMessage &&
          <Row className="ml-1 mr-1 pt-2 pb-2">
            <Col className="text-danger font-weight-bold">
              {formikProps.errors.uniqueYearsErrorMessage}
            </Col>
          </Row>
          }
          <ModalFooter className="pr-0 pl-0">
            <Row className="w-100 d-flex m-0">
              <Col className="align-self-center" sm="4">
                <Button size="sm"
                        outline
                        color="primary"
                        onClick={() => handleAddYear(formikProps)}>
                  <FontAwesomeIcon icon="plus-circle" className="mr-2"/>
                  Add Year
                </Button>
              </Col>
              <Col sm="8" className="d-flex justify-content-end">
                <Button color="success"
                        className="mr-2"
                        disabled={formikProps.isSubmitting || (!hasRemovedYear && !formikProps.dirty) || !formikProps.isValid}
                        onClick={formikProps.submitForm}>
                  Save
                </Button>
                <Button color="secondary"
                        onClick={() => setModalIsOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default BonaFideDenialYearCard;