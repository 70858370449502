import {useEffect, useState} from 'react';
import {Button, Card, CardHeader, Col, Container, Row, Table} from 'reactstrap';

import {ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {ProgressReport, StatusReport, TrajectoryReport} from '../types';
import {reportApi} from '../api';
import *  as messages from '../messages';

const Reports = () => {
    const {showErrorAlert} = useAlerts();
    const [loadingState, setLoadingState] = useState({
      loadError: false,
      loading: false,
      processing: false
    });
    const [statusReport, setStatusReport] = useState<StatusReport | undefined>(undefined);
    const [progressReport, setProgressReport] = useState<ProgressReport | undefined>(undefined);
    const [trajectoryReport, setTrajectoryReport] = useState<TrajectoryReport | undefined>(undefined);

    useEffect(() => {
      const loadReports = async () => {
        setLoadingState({loadError: false, loading: true, processing: false});
        try {
          const [statusReport, progressReport, trajectoryReport] = await Promise.all([
            reportApi.findStatusReport(),
            reportApi.findProgressReport(),
            reportApi.findTrajectoryReport()
          ]);
          setStatusReport(statusReport);
          setProgressReport(progressReport);
          setTrajectoryReport(trajectoryReport);
          setLoadingState({loadError: false, loading: false, processing: false});
        } catch (e) {
          showErrorAlert(messages.API_FAILURE);
          setLoadingState({loading: false, loadError: true, processing: false});
        }
      };
      loadReports().then();
    }, [showErrorAlert]);

    const handleDownloadReport = async (report: 'trajectory' | 'status' | 'progress') => {
      try {
        setLoadingState({loadError: false, processing: true, loading: false});
        if (report === 'progress') {
          await reportApi.downloadProgressReport();
        } else if (report === 'status') {
          await reportApi.downloadStatusReport();
        } else if (report === 'trajectory') {
          await reportApi.downloadTrajectoryReport();
        }
      } catch (e) {
        showErrorAlert(messages.API_FAILURE);
      } finally {
        setLoadingState({loadError: false, processing: false, loading: false});
      }
    };

    return <Container fluid>
      {!loadingState.loadError && loadingState.loading && <ProgressIndicator/>}
      {!loadingState.loadError && !loadingState.loading &&
      statusReport && progressReport && trajectoryReport && <>
        <Row>
          <Col sm="4">
            <Card>
              <CardHeader>
                <Row className="d-flex">
                  <Col className="align-self-center" sm="6">
                    Progress Report
                  </Col>
                  <Col className="d-flex justify-content-end" sm="6">
                    <Button color="primary"
                            disabled={loadingState.processing}
                            onClick={() => handleDownloadReport('progress')}
                            size="sm">
                      Export
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <Table responsive striped>
                <thead>
                  <tr>
                    <th>Year</th>
                    <th>Supervisor Decision</th>
                    <th>Appeal Count</th>
                  </tr>
                </thead>
                <tbody>
                  {progressReport.map((reportYear, index) => <tr key={index}>
                    <td>{reportYear.year}</td>
                    <td>{reportYear.supervisorDecision}</td>
                    <td>{reportYear.appealCount}</td>
                  </tr>)}
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col sm="4">
            <Card>
              <CardHeader>
                <Row className="d-flex">
                  <Col className="align-self-center" sm="6">
                    Status Report
                  </Col>
                  <Col className="d-flex justify-content-end" sm="6">
                    <Button color="primary"
                            disabled={loadingState.processing}
                            onClick={() => handleDownloadReport('status')}
                            size="sm">
                      Export
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <Table responsive striped>
                <thead>
                  <tr>
                    <th>Year</th>
                    <th>Status</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  {statusReport.map((reportYear, index) => <tr key={index}>
                    <td>{reportYear.year}</td>
                    <td>{reportYear.status}</td>
                    <td>{reportYear.count}</td>
                  </tr>)}
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col sm="4">
            <Card>
              <CardHeader>
                <Row className="d-flex">
                  <Col className="align-self-center" sm="6">
                    Trajectory Report
                  </Col>
                  <Col className="d-flex justify-content-end" sm="6">
                    <Button color="primary"
                            disabled={loadingState.processing}
                            onClick={() => handleDownloadReport('trajectory')}
                            size="sm">
                      Export
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <Table responsive>
                <thead>
                  <tr>
                    <th className="text-center">Denial Count</th>
                    <th className="text-center">Appeal Count</th>
                    <th className="text-center">Finalized Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">{trajectoryReport.denialCount}</td>
                    <td className="text-center">{trajectoryReport.appealCount}</td>
                    <td className="text-center">{trajectoryReport.finalizedCount}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </>}
    </Container>;
  }
;

export default Reports;