import {Card, CardHeader} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';

import {CustomTable, ExportBatchItem} from '@reasoncorp/kyber-js';

type Props = {
  items: ExportBatchItem[]
}

const ImportTable = ({
                       items
                     }: Props) => {
  const tableProps = {
    headers: [
      {sortKey: 'reasonId', title: 'Reason ID', className: 'text-center align-middle'},
      {sortKey: 'parcelNumber', title: 'Parcel ID', className: 'text-center align-middle'},
      {sortKey: 'denialId', title: 'Denial ID', className: 'text-center align-middle'},
      {sortKey: 'ownerNames', title: 'Owner', className: 'text-center align-middle'},
      {sortKey: 'address', title: 'Address', className: 'text-center align-middle'},
      {sortKey: 'county', title: 'County', className: 'text-center align-middle'},
      {sortKey: 'majorReason', title: 'Major Reason', className: 'text-center align-middle'},
      {sortKey: 'imported', title: 'Status', className: 'text-center align-middle'},
      {sortKey: 'errorMessage', title: 'Error Message', className: 'text-center align-middle'}
    ],
    items,
    noResultsMessage: 'No Imports available to display in report.',
    renderRow: (exportBatchItem: ExportBatchItem) => {
      return (
        <tr key={exportBatchItem.reasonId}>
          <td className="align-left">{exportBatchItem.reasonId}</td>
          <td className="align-left">{exportBatchItem.parcelNumber}</td>
          <td role="link">
            {exportBatchItem.denialId && exportBatchItem.denialFileNumber &&
            <Link to={`/denials/${exportBatchItem.denialId}`}>
              {exportBatchItem.denialFileNumber}
            </Link>}
          </td>
          <td className="align-left">{exportBatchItem.ownerNames}</td>
          <td className="align-left text-nowrap">{exportBatchItem.address}</td>
          <td className="align-left">{exportBatchItem.county}</td>
          <td className="align-left">{exportBatchItem.majorReason}</td>
          <td className="text-center">{exportBatchItem.imported ?
            <FontAwesomeIcon icon="check-circle" className="text-success"/>
            :
            <FontAwesomeIcon icon="times-circle" className="text-danger"/>
          }
          </td>
          <td className="align-left">{exportBatchItem.errorMessage}</td>
        </tr>
      );
    }
  };

  return <Card>
    <CardHeader>Import List</CardHeader>
    <CustomTable {...tableProps}/>
  </Card>;
};

export default ImportTable;