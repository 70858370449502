import {useContext, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Container, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import {Link} from 'react-router-dom';

import {
  CustomTable,
  FormikDatePicker,
  FormikInput,
  FormikSelect,
  ProgressIndicator,
  useAlerts,
  useUserContext
} from '@reasoncorp/kyber-js';

import {DenialSearchResponse} from '../types';
import {DenialSearchRequest} from '../types/request';
import {denialApi} from '../api';
import {formatDate} from '../util';
import {DenialCreateModal, DenialNavBar} from '../components/denials';
import * as messages from '../messages';
import {denialSearchSchema} from '../schema/denial';
import {CollectionContext} from '../contexts';

const Denials = () => {
  const [hasSearched, setHasSearched] = useState(false);
  const [denials, setDenials] = useState<DenialSearchResponse[]>([]);
  const [denialModalIsOpen, setDenialModalIsOpen] = useState(false);
  const {showErrorAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: false, isExporting: false});
  const {collections} = useContext(CollectionContext);
  const {permissions} = useUserContext();

  const handleSearch = async (denialSearchRequest: DenialSearchRequest) => {
    try {
      setLoadingState({loading: true, isExporting: false});
      const denials = await denialApi.search(denialSearchRequest);
      setDenials(denials);
      setHasSearched(true);
    } catch (e) {
      showErrorAlert(messages.API_FAILURE);
    }

    setLoadingState({loading: false, isExporting: false});
  };

  const handleExportSearch = async (denialSearchRequest: DenialSearchRequest) => {
    try {
      setLoadingState({loading: false, isExporting: true});
      await denialApi.downloadSearch(denialSearchRequest);
    } catch (e) {
      showErrorAlert(messages.API_FAILURE);
    }
    setLoadingState({loading: false, isExporting: false});
  };

  const tableProps = {
    headers: [
      {title: 'Parcel ID', sortKey: 'parcel.parcelNumber'},
      {title: 'Owners', sortKey: 'owner.ownerNames'},
      {title: 'Parcel Address', sortKey: 'parcel.address.fullDisplay'},
      {title: 'Status', sortKey: 'appealStatus'},
      {title: 'Denial Letter', className: 'text-center text-nowrap', sortKey: 'letterSentOn'},
      {title: 'County', className: 'text-center', sortKey: 'parcel.county'},
      {title: 'Reason', className: 'text-center', sortKey: 'majorReason'},
      {title: 'Appeal', className: 'text-center', sortKey: 'appealFileNumber'},
      {title: 'Denial ID', className: 'text-center', sortKey: 'fileNumber'}
    ],
    renderRow: (denial: DenialSearchResponse) => {
      return <tr key={denial.id}>
        <td className="text-nowrap">
          <Link to={`/denials/${denial.id}`}>
            {denial.parcel.parcelNumber}
          </Link>
        </td>
        <td>{denial.owner.ownerNames}</td>
        <td>{denial.parcel.address.fullDisplay}</td>
        <td>{denial.appealStatusDisplayValue}</td>
        <td className="text-center">{formatDate(denial.letterSentOn)}</td>
        <td className="text-center">{denial.parcel.county}</td>
        <td className="text-center">{denial.majorReason}</td>
        <td className="text-center">
          {denial.appealFileNumber && <Link to={`/appeals/${denial.appealId}`}>
            {denial.appealFileNumber}
          </Link>}
        </td>
        <td className="text-center">{denial.fileNumber}</td>
      </tr>;
    },
    initialSort: {direction: 'desc' as const, sortKey: 'year'},
    items: denials,
    noResultsMessage: 'No denials found.',
    paginatorConfig: {
      perPage: 100
    }
  };

  const handleReset = (resetForm: () => void) => {
    setDenials([]);
    setHasSearched(false);
    resetForm();
  };

  const initialValues = {
    parcelNumber: '',
    ownerNames: '',
    majorReason: '',
    fileNumber: '',
    appealFileNumber: '',
    county: '',
    appealStatus: '',
    parcelAddress: '',
    letterSentOn: null
  };

  return <>
    <Formik initialValues={initialValues}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={handleSearch}
            validationSchema={denialSearchSchema}>
      {(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
          <Container fluid>
            {(permissions.hasWriteAccess || permissions.hasImportAndLetterAccess) && <Row className="mb-4">
              <Col className="d-flex justify-content-end">
                <DenialNavBar displayCreateButton={true}
                              openCreateModal={() => setDenialModalIsOpen(true)}/>
              </Col>
            </Row>}
            <Card className="mb-4">
              <CardHeader>Search Denials</CardHeader>
              <CardBody>
                <Row>
                  <Col sm="2">
                    <FormikInput name="parcelNumber"
                                 labelText="Parcel ID"/>
                  </Col>
                  <Col sm="3">
                    <FormikInput name="ownerNames"
                                 labelText="Owners"/>
                  </Col>
                  <Col sm="3">
                    <FormikInput name="parcelAddress"
                                 labelText="Parcel Address"/>
                  </Col>
                  <Col sm="4">
                    <FormikSelect name="appealStatus"
                                  labelText="Status">
                      <option value="">Select</option>
                      {collections?.appealStatuses && Object.keys(collections?.appealStatuses).sort().map(value =>
                        <option value={value} key={value}>{collections.appealStatuses[value]}</option>)
                      }
                    </FormikSelect>
                  </Col>
                </Row>
                <Row>
                  <Col sm="1">
                    <FormikDatePicker name="letterSentOn"
                                      labelText="Denial Letter"/>
                  </Col>
                  <Col sm="2">
                    <FormikSelect name="county"
                                  labelText="County">
                      <option value="">Select</option>
                      {collections?.counties?.map(county =>
                        <option value={county} key={county}>{county}</option>)
                      }
                    </FormikSelect>
                  </Col>
                  <Col sm="3">
                    <FormikSelect name="majorReason"
                                  labelText="Reason">
                      <option value="">Select</option>
                      {collections?.majorReasons?.map(majorReason =>
                        <option value={majorReason} key={majorReason}>{majorReason}</option>)
                      }
                    </FormikSelect>
                  </Col>
                  <Col sm="3">
                    <FormikInput name="appealFileNumber"
                                 labelText="Appeal"/>
                  </Col>
                  <Col sm="3">
                    <FormikInput name="fileNumber"
                                 labelText="Denial ID"/>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-end">
                    <Button color="success"
                            size="sm"
                            type="submit"
                            disabled={formikProps.isSubmitting}
                            onClick={formikProps.submitForm}
                            className="mr-2">
                      Search
                    </Button>
                    <Button color="danger"
                            onClick={() => handleReset(formikProps.resetForm)}
                            size="sm">
                      Reset
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {loadingState.loading && <ProgressIndicator className="mb-4"/>}
            {hasSearched && <Card>
              <CardHeader>
                <Row>
                  <Col sm="6" className="align-self-center">
                    Search Results
                  </Col>
                  <Col sm="6" className="d-flex justify-content-end">
                    <Button color="primary"
                            disabled={loadingState.isExporting}
                            onClick={() => handleExportSearch(formikProps.values)}
                            size="sm">
                      Export
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CustomTable {...tableProps}/>
            </Card>}
          </Container>
        </Form>)}
    </Formik>
    <DenialCreateModal isOpen={denialModalIsOpen}
                       toggleOpen={() => setDenialModalIsOpen(!denialModalIsOpen)}/>
  </>;
};

export default Denials;