import {useContext} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';
import {useNavigate} from 'react-router-dom';

import {FormikDatePicker, FormikInput, FormikSelect, useAlerts} from '@reasoncorp/kyber-js';

import {Parcel} from '../../types';
import {QualifiedErrorRequest} from '../../types/request';
import {qualifiedErrorCreateSchema} from '../../schema/qualifiedError';
import {CollectionContext} from '../../contexts';
import {qualifiedErrorApi} from '../../api';
import * as messages from '../../messages';

type Props = {
  isOpen: boolean
  toggleOpen: () => void
  parcel?: Parcel
  onSave?: () => void
}

const QualifiedErrorCreateModal = ({
                                     isOpen,
                                     toggleOpen,
                                     parcel,
                                     onSave
                                   }: Props) => {
  const {collections} = useContext(CollectionContext);
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const navigate = useNavigate();

  const initialValues: QualifiedErrorRequest = {
    parcel: {
      parcelNumber: parcel?.parcelNumber ?? '',
      county: parcel?.county ?? '',
      localUnit: parcel?.localUnit ?? '',
      address: {
        directionPrefix: parcel?.address.directionPrefix ?? '',
        directionSuffix: parcel?.address.directionSuffix ?? '',
        streetNumber: parcel?.address.streetNumber ?? '',
        streetName: parcel?.address.streetName ?? '',
        streetType: parcel?.address.streetType ?? '',
        unit: parcel?.address.unit ?? '',
        city: parcel?.address.city ?? '',
        state: 'MI',
        zip: parcel?.address.zip ?? ''
      }
    },
    receivedOn: null
  };

  const handleSave = async (qualifiedErrorRequest: QualifiedErrorRequest, formikHelpers: FormikHelpers<QualifiedErrorRequest>) => {
    try {
      const qualifiedError = await qualifiedErrorApi.create(qualifiedErrorRequest);
      toggleOpen();
      showSuccessAlert(messages.QUALIFIED_ERROR_SAVE_SUCCESSFUL);
      formikHelpers.resetForm();
      if (onSave) {
        onSave();
      } else {
        navigate(`/qualified-errors/${qualifiedError.id}`);
      }
    } catch (e) {
      showErrorAlert(messages.QUALIFIED_ERROR_SAVE_FAILURE);
    }
  };

  return <Modal isOpen={isOpen}
                toggle={toggleOpen}
                autoFocus={false}
                aria-modal={true}
                returnFocusAfterClose={true}
                size="xl">
    <ModalHeader toggle={toggleOpen}
                 tag="h2"
                 className="h5">
      Create New Qualified Error
    </ModalHeader>
    <h3 className="h6 bg-light p-3">Property Information</h3>
    <Formik initialValues={initialValues}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={handleSave}
            validationSchema={qualifiedErrorCreateSchema}>
      {(formikProps) => (<Form onSubmit={formikProps.submitForm} autoComplete="off">
        <ModalBody>
          <Row>
            <Col sm="4">
              <FormikInput name="parcel.parcelNumber"
                           autoFocus
                           labelText="Parcel #"/>
            </Col>
            <Col sm="4">
              <FormikSelect name="parcel.county"
                            labelText="County">
                <option value="">Select</option>
                {collections?.counties.map(county =>
                  <option value={county} key={county}>{county}</option>)
                }
              </FormikSelect>
            </Col>
            <Col sm="4">
              <FormikInput name="parcel.localUnit"
                           labelText="City/Township"/>
            </Col>
          </Row>
          <Row>
            <Col sm="2">
              <FormikInput name="parcel.address.directionPrefix"
                           labelText="Dir. Prefix"/>
            </Col>
            <Col sm="2">
              <FormikInput name="parcel.address.streetNumber"
                           labelText="Street #"/>
            </Col>
            <Col sm="3">
              <FormikInput name="parcel.address.streetName"
                           labelText="Street Name"/>
            </Col>
            <Col sm="2">
              <FormikInput name="parcel.address.streetType"
                           labelText="Street Type"/>
            </Col>
            <Col sm="2">
              <FormikInput name="parcel.address.directionSuffix"
                           labelText="Dir. Suffix"/>
            </Col>
            <Col sm="1">
              <FormikInput name="parcel.address.unit"
                           labelText="APT #"/>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <FormikInput name="parcel.address.city"
                           labelText="City"/>
            </Col>
            <Col sm="4">
              <FormikInput name="parcel.address.state"
                           disabled={true}
                           labelText="State Abbr"/>
            </Col>
            <Col sm="4">
              <FormikInput name="parcel.address.zip"
                           labelText="Zip Code"/>
            </Col>
          </Row>
        </ModalBody>
        <h3 className="h6 bg-light p-3">File Information</h3>
        <ModalBody>
          <Row>
            <Col>
              <FormikDatePicker name="receivedOn"
                                labelText="Date Received"/>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row className="justify-content-end">
            <Col>
              <Button color="primary"
                      className="mr-2"
                      disabled={!formikProps.isValid || formikProps.isSubmitting || !formikProps.dirty}
                      onClick={formikProps.submitForm}>
                Save
              </Button>
              <Button color="secondary"
                      onClick={toggleOpen}>
                Cancel
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Form>)}
    </Formik>
  </Modal>;
};

export default QualifiedErrorCreateModal;