import {AppealResponse, AppealSearchResponse, BlobResponse, CourtHearing, DocumentResponse, MttHearing} from '../types';
import {
  AppealAnalystReviewRequest,
  AppealRequest,
  AppealSearchRequest,
  AppealStatusRequest,
  InformalHearingRequest
} from '../types/request';
import {proServApi} from './apiUtils';

export const find = (id: number): Promise<AppealResponse> => proServApi.getWithJsonResponse(
  `/appeals/${id}`
);

export const create = (denialId: number): Promise<AppealResponse> => proServApi.postWithJsonResponse(
  `/appeals/${denialId}`
);

export const search = (appealSearchRequest: AppealSearchRequest): Promise<AppealSearchResponse[]> =>
  proServApi.postWithJsonResponse(
    '/appeals/search',
    {
      body: JSON.stringify(appealSearchRequest)
    }
  );

export const accept = (id: number): Promise<AppealResponse> => proServApi.patchWithJsonResponse(
  `/appeals/${id}/accept`
);

export const reject = (id: number): Promise<AppealResponse> => proServApi.patchWithJsonResponse(
  `/appeals/${id}/reject`
);

export const findDocuments = (id: number): Promise<DocumentResponse[]> => proServApi.getWithJsonResponse(
  `/appeals/${id}/documents`
);

export const save = (id: number, appealRequest: AppealRequest): Promise<AppealResponse> => proServApi.putWithJsonResponse(
  `/appeals/${id}`,
  {
    body: JSON.stringify(appealRequest)
  }
);

export const saveStatus = (id: number, appealStatusRequest: AppealStatusRequest): Promise<AppealResponse> => proServApi.patchWithJsonResponse(
  `/appeals/${id}/status`,
  {
    body: JSON.stringify(appealStatusRequest)
  }
);

export const saveAnalystReview = (id: number, appealAnalystReviewRequest: AppealAnalystReviewRequest): Promise<AppealResponse> => proServApi.putWithJsonResponse(
  `/appeals/${id}/analyst-review`,
  {
    body: JSON.stringify(appealAnalystReviewRequest)
  }
);

export const saveSupervisorReview = (id: number, appealSupervisorReviewRequest: FormData): Promise<AppealResponse> => proServApi.putWithJsonResponse(
  `/appeals/${id}/supervisor-review`,
  {
    body: appealSupervisorReviewRequest
  }
);

export const saveCourtHearing = (id: number, courtHearing: CourtHearing): Promise<AppealResponse> => proServApi.putWithJsonResponse(
  `/appeals/${id}/court-hearing`,
  {
    body: JSON.stringify(courtHearing)
  }
);

export const saveMttHearing = (id: number, mttHearing: MttHearing): Promise<AppealResponse> => proServApi.putWithJsonResponse(
  `/appeals/${id}/mtt-hearing`,
  {
    body: JSON.stringify(mttHearing)
  }
);

export const saveInformalHearing = (id: number, informalHearingRequest: InformalHearingRequest): Promise<AppealResponse> => proServApi.putWithJsonResponse(
  `/appeals/${id}/informal-hearing`,
  {
    body: JSON.stringify(informalHearingRequest)
  }
);

export const download = (id: number): Promise<BlobResponse> => proServApi.getWithBlobResponse(
  `/appeals/${id}/download`
);
