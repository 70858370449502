import {useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik} from 'formik';

import {CustomTable, FormikInput, FormikPhoneInput, useAlerts, useUserContext} from '@reasoncorp/kyber-js';

import {Call} from '../../types';
import {CallRequest} from '../../types/request';
import {formatDateTime} from '../../util';
import {callApi} from '../../api';
import * as messages from '../../messages';
import {callSchema} from '../../schema';

type Props = {
  calls: Call[]
  parcelId: number
  onSaveSuccess: () => void
}

const CallsCard = ({
                     calls,
                     parcelId,
                     onSaveSuccess
                   }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const {permissions} = useUserContext();

  const handleSave = async (callRequest: CallRequest) => {
    setModalIsOpen(false);
    try {
      await callApi.create(parcelId, callRequest);
      showSuccessAlert(messages.CALL_SAVE_SUCCESSFUL);
      await onSaveSuccess();
    } catch (e) {
      showErrorAlert(messages.CALL_SAVE_FAILURE);
    }
  };

  const initialValues = {
    callerName: '',
    text: '',
    phoneNumber: ''
  };

  const tableProps = {
    items: calls,
    noResultsMessage: 'No calls.',
    initialSort: {sortKey: 'createdAt', direction: 'desc' as 'desc'},
    headers: [
      {title: 'Date', sortKey: 'createdAt', className: 'w-15 text-center'},
      {title: 'Caller Name'},
      {title: 'Phone Number'},
      {title: 'Notes'},
      {title: 'User', className: 'w-15 text-center'}
    ],
    renderRow: (call: Call) => <tr key={call.id}>
      <td className="text-center">{formatDateTime(call.createdAt)}</td>
      <td>{call.callerName}</td>
      <td>{call.phoneNumber}</td>
      <td>{call.text}</td>
      <td className="text-center">{call.username}</td>
    </tr>
  };

  return <>
    <Card className="mb-4">
      <CardHeader>
        <Row>
          <Col className="align-self-center" sm="6">
            Calls
          </Col>
          <Col className="justify-content-end d-flex" sm="6">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Create Call
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <CustomTable {...tableProps} />
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="lg">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Create Call
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={callSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col sm="6">
                <FormikInput autoFocus
                             name="callerName"
                             labelText="Caller name"/>
              </Col>
              <Col sm="6">
                <FormikPhoneInput name="phoneNumber"
                                  labelText="Phone Number"/>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikInput name="text"
                             type="textarea"
                             rows={4}
                             labelText="Notes"/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row className="justify-content-end">
              <Col>
                <Button color="success"
                        className="mr-2"
                        disabled={formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
                        onClick={formikProps.submitForm}>
                  Save
                </Button>
                <Button color="secondary"
                        onClick={() => setModalIsOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default CallsCard;