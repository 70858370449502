import {useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikDatePicker, FormikNumberInput, useUserContext} from '@reasoncorp/kyber-js';

import {BonaFideFileInformation, BonaFideResponse} from '../../types';
import {BonaFideRequest} from '../../types/request';
import {bonaFideFileInformationSchema} from '../../schema/bonaFide';
import {formatDate} from '../../util';

type Props = {
  bonaFide: BonaFideResponse
  onSave: (bonaFideRequest: BonaFideRequest) => void
}

const BonaFideInformationCard = ({
                                   bonaFide,
                                   onSave
                                 }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {permissions} = useUserContext();

  const handleSave = async (bonaFideInformationRequest: BonaFideFileInformation) => {
    setModalIsOpen(false);
    const bonaFideRequest = {
      ...bonaFide,
      fileInformation: bonaFideInformationRequest
    } as BonaFideRequest;
    await onSave(bonaFideRequest);
  };

  const initialValues: BonaFideFileInformation = {
    yearsBilled: bonaFide.fileInformation?.yearsBilled ?? '',
    numberReceived: bonaFide.fileInformation?.numberReceived ?? '',
    receivedOn: bonaFide.fileInformation?.receivedOn || null
  };

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col className="align-self-center" sm="6">
            File Information
          </Col>
          <Col className="justify-content-end d-flex" sm="6">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table bordered>
        <thead>
          <tr>
            <th>Received On</th>
            <th>Years Billed</th>
            <th>Number Received</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{formatDate(bonaFide.fileInformation?.receivedOn)}</td>
            <td>{bonaFide.fileInformation?.yearsBilled}</td>
            <td>{bonaFide.fileInformation?.numberReceived}</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="lg">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit File Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={bonaFideFileInformationSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col sm="4">
                <FormikDatePicker name="receivedOn"
                                  labelText="Date Received"/>
              </Col>
              <Col sm="4">
                <FormikNumberInput name="yearsBilled"
                                   labelText="Number of Years Billed"/>
              </Col>
              <Col sm="4">
                <FormikNumberInput name="numberReceived"
                                   labelText="Bona Fides Received"/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-end">
            <Button color="success"
                    className="mr-2"
                    disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
                    onClick={formikProps.submitForm}>
              Save
            </Button>
            <Button color="secondary"
                    onClick={() => setModalIsOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default BonaFideInformationCard;