import {proServApi} from './apiUtils';
import {BlobResponse, DocumentResponse, QualifiedErrorResponse, QualifiedErrorSearchResponse} from '../types';
import {QualifiedErrorRequest, QualifiedErrorSearchRequest} from '../types/request';

export const search = (qualifiedErrorSearchRequest: QualifiedErrorSearchRequest): Promise<QualifiedErrorSearchResponse[]> =>
  proServApi.postWithJsonResponse(
    `/qualified-errors/search`,
    {
      body: JSON.stringify(qualifiedErrorSearchRequest)
    }
  );

export const find = (id: number): Promise<QualifiedErrorResponse> => proServApi.getWithJsonResponse(
  `/qualified-errors/${id}`
);

export const findDocuments = (id: number): Promise<DocumentResponse[]> => proServApi.getWithJsonResponse(
  `/qualified-errors/${id}/documents`
);

export const create = (qualifiedErrorRequest: QualifiedErrorRequest): Promise<QualifiedErrorResponse> => proServApi.postWithJsonResponse(
  `/qualified-errors`,
  {
    body: JSON.stringify(qualifiedErrorRequest)
  }
);

export const save = (id: number, qualifiedErrorRequest: QualifiedErrorRequest): Promise<QualifiedErrorResponse> =>
  proServApi.putWithJsonResponse(
    `/qualified-errors/${id}`,
    {
      body: JSON.stringify(qualifiedErrorRequest)
    }
  );

export const download = (id: number): Promise<BlobResponse> => proServApi.getWithBlobResponse(
  `/qualified-errors/${id}/download`
);