import {useEffect, useState} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import {useNavigate, useParams} from 'react-router-dom';

import {ProgressIndicator, useAlerts, useUserContext} from '@reasoncorp/kyber-js';

import {
  CallsCard,
  DocumentsCard,
  MailingAddressCard,
  NotesCard,
  OwnerCard,
  ParcelCard,
  PowerOfAttorneyCard,
  RecordNavBar,
  RelatedParcelRecordsCard
} from '../components/shared';
import {DenialInformationCard, DenialTmaInformationCard, DenialYearsDeniedCard} from '../components/denials';
import {Address, Call, DenialResponse, DocumentResponse, Note} from '../types';
import {DenialRequest, OwnerRequest, ParcelRequest, PowerOfAttorneyRequest} from '../types/request';
import {appealApi, callApi, denialApi, noteApi, scanApi} from '../api';
import * as messages from '../messages';

const Denial = () => {
  const id = Number(useParams<{id: string}>().id);
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [denial, setDenial] = useState<DenialResponse | undefined>(undefined);
  const [documents, setDocuments] = useState<DocumentResponse[]>([]);
  const [scanExists, setScanExists] = useState<boolean | undefined>(undefined);
  const [notes, setNotes] = useState<Note[]>([]);
  const [calls, setCalls] = useState<Call[]>([]);
  const [loadingState, setLoadingState] = useState({loadError: false, loading: false, processing: false});
  const navigate = useNavigate();
  const {permissions} = useUserContext();

  useEffect(() => {
    const loadDenial = async () => {
      setLoadingState({loadError: false, loading: true, processing: false});
      try {
        const [denial, documents] = await Promise.all([
          denialApi.find(id),
          denialApi.findDocuments(id)
        ]);
        setDenial(denial);
        setDocuments(documents);
        const [notes, calls, scanExists] = await Promise.all([
          noteApi.findAll(denial.parcel.id),
          callApi.findAll(denial.parcel.id),
          scanApi.find(denial.reasonId, denial.year)
        ]);
        setNotes(notes);
        setCalls(calls);
        setScanExists(scanExists);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE);
        setLoadingState({loading: false, loadError: true, processing: false});
      }
    };
    loadDenial().then();
  }, [id, showErrorAlert]);

  const loadDenial = async () => {
    try {
      const [denial, documents] = await Promise.all([
        denialApi.find(id),
        denialApi.findDocuments(id)
      ]);
      setDenial(denial);
      setDocuments(documents);

      const [notes, calls] = await Promise.all([
        noteApi.findAll(denial.parcel.id),
        callApi.findAll(denial.parcel.id)
      ]);
      setNotes(notes);
      setCalls(calls);

      const scanExists = await scanApi.find(denial.reasonId, denial.year);
      setScanExists(scanExists);
    } catch (e) {
      showErrorAlert(messages.API_FAILURE);
      setLoadingState({loading: false, loadError: true, processing: false});
    }
  };

  const handleCreateAppeal = async () => {
    try {
      setLoadingState({loading: false, loadError: false, processing: true});
      await appealApi.create(id);
      const denial = await denialApi.find(id);
      setDenial(denial);
      showSuccessAlert(messages.APPEAL_CREATE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.APPEAL_CREATE_FAILURE);
    }
    setLoadingState({loading: false, loadError: false, processing: false});
  };

  const handleDownloadPdf = async () => {
    try {
      setLoadingState({loading: false, loadError: false, processing: true});
      await denialApi.download(id);
    } catch (e) {
      showErrorAlert(messages.DOCUMENT_DOWNLOAD_FAILURE);
    }
    setLoadingState({loading: false, loadError: false, processing: false});
  };

  const handleSave = async (denialRequest: DenialRequest) => {
    try {
      setLoadingState({loading: false, loadError: false, processing: true});
      const denial = await denialApi.save(id, denialRequest);
      setDenial(denial);
      showSuccessAlert(messages.DENIAL_SAVE_SUCCESSFUL);
      setLoadingState({loading: false, loadError: false, processing: false});
    } catch (e) {
      showErrorAlert(messages.DENIAL_SAVE_FAILURE);
      setLoadingState({loading: false, loadError: true, processing: false});
    }
  };

  const handleScanDownload = async () => {
    try {
      setLoadingState({loading: false, loadError: false, processing: true});
      await scanApi.download(denial!.reasonId, denial!.year);
      setLoadingState({loading: false, loadError: false, processing: false});
    } catch (e) {
      showErrorAlert(messages.DOCUMENT_DOWNLOAD_FAILURE);
      setLoadingState({loading: false, loadError: false, processing: false});
    }
  };

  const handlePowerOfAttorneySave = (powerOfAttorney: PowerOfAttorneyRequest) => handleSave({
    ...denial,
    powerOfAttorney
  } as DenialRequest);

  const handleMailingAddressSave = (mailingAddress: Address) => handleSave({
    ...denial,
    mailingAddress
  } as DenialRequest);

  const handleOwnerSave = (owner: OwnerRequest) => handleSave({
    ...denial,
    owner
  } as DenialRequest);

  const handleParcelSave = (parcel: ParcelRequest) => handleSave({
    ...denial,
    parcel
  } as DenialRequest);

  const renderButtons = () => <>
    {denial?.appealId &&
      <Button color="primary"
              onClick={() => navigate(`/appeals/${denial?.appealId}`)}
              className="mr-2">
        View Appeal
      </Button>}
    {!denial?.appealId && permissions.hasWriteAccess && <Button color="primary"
                                                                disabled={loadingState.processing}
                                                                onClick={async () => await handleCreateAppeal()}
                                                                className="mr-2">
      Create Appeal
    </Button>}
    <Button color="primary"
            disabled={loadingState.processing}
            onClick={async () => await handleDownloadPdf()}
            className="mr-2">
      View PDF
    </Button>
  </>;

  return <Container fluid>
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loadError && !loadingState.loading && denial && <>
      <Row className="mb-4">
        <Col className="align-self-center" sm="3">
          <h1 className="h5 mb-0">Denial: {denial.fileNumber}</h1>
        </Col>
        <Col className="d-flex justify-content-end" sm="9">
          <RecordNavBar parcel={denial.parcel}
                        denialId={denial.id}
                        bonaFideId={denial.bonaFideId}
                        interestWaiverId={denial.interestWaiverId}
                        currentRecordType="DENIAL"
                        createButtonsDisabled={denial.year < new Date().getFullYear() - 5}
                        onRecordSave={loadDenial}
                        renderButtons={renderButtons}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm="12">
          <ParcelCard parcel={denial.parcel}
                      onSave={handleParcelSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm="6">
          <Row className="mb-4">
            <Col>
              <OwnerCard owner={denial.owner}
                         onSave={handleOwnerSave}/>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <MailingAddressCard mailingAddress={denial.mailingAddress}
                                  onSave={handleMailingAddressSave}/>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <PowerOfAttorneyCard powerOfAttorney={denial.powerOfAttorney}
                                   onSave={handlePowerOfAttorneySave}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <RelatedParcelRecordsCard parcelId={denial.parcel.id}/>
            </Col>
          </Row>
        </Col>
        <Col sm="6">
          <Row className="mb-4">
            <Col>
              <DenialInformationCard denial={denial}
                                     onSave={handleSave}/>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <DenialYearsDeniedCard denial={denial}
                                     onSave={handleSave}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <DenialTmaInformationCard denial={denial}
                                        processing={loadingState.processing}
                                        scanExists={scanExists}
                                        onScanDownload={handleScanDownload}/>
            </Col>
          </Row>
        </Col>
      </Row>
      <NotesCard notes={notes}
                 parcelId={denial.parcel.id}
                 onSaveSuccess={loadDenial}/>
      <CallsCard calls={calls}
                 parcelId={denial.parcel.id}
                 onSaveSuccess={loadDenial}/>
      <DocumentsCard documents={documents}
                     parcelId={denial.parcel.id}
                     onSuccess={loadDenial}/>
    </>}
  </Container>;
};

export default Denial;