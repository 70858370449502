import {useContext, useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikDatePicker, FormikInput, FormikSelect, useUserContext} from '@reasoncorp/kyber-js';

import {formatDate} from '../../util';
import {CourtHearing} from '../../types';
import {courtHearingSchema} from '../../schema/appeal';
import {CollectionContext} from '../../contexts';

type Props = {
  courtHearing: CourtHearing
  onSave: (courtHearing: CourtHearing) => void
}

const AppealCourtHearingCard = ({
                                  courtHearing,
                                  onSave
                                }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {collections} = useContext(CollectionContext);
  const {permissions} = useUserContext();

  const handleSave = async (courtHearing: CourtHearing) => {
    await onSave(courtHearing);
    setModalIsOpen(false);
  };

  const initialValues = {...courtHearing};

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col className="align-self-center" sm="6">
            Court of Appeals Information
          </Col>
          <Col className="justify-content-end d-flex" sm="6">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>Docket Number</th>
            <th>Date Sent</th>
            <th>Decision</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{courtHearing.docketNumber}&nbsp;</td>
            <td>{formatDate(courtHearing.sentOn)}&nbsp;</td>
            <td>{courtHearing.ruling}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="lg">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Court of Appeals Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={courtHearingSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col sm="4">
                <FormikInput autoFocus
                             name="docketNumber"
                             labelText="Docket Number"/>
              </Col>
              <Col sm="4">
                <FormikDatePicker name="sentOn"
                                  labelText="Date Sent"/>
              </Col>
              <Col sm="4">
                <FormikSelect name="ruling"
                              labelText="Decision">
                  <option value="">Select</option>
                  {collections?.judgementDecisions.map(judgementDecision =>
                    <option key={judgementDecision} value={judgementDecision}>{judgementDecision}</option>)}
                </FormikSelect>
              </Col>
            </Row>

          </ModalBody>
          <ModalFooter>
            <Row className="justify-content-end">
              <Col>
                <Button color="success"
                        className="mr-2"
                        disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
                        onClick={formikProps.submitForm}>
                  Save
                </Button>
                <Button color="secondary" onClick={() => setModalIsOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default AppealCourtHearingCard;