import {useState} from 'react';
import {Button, Card, CardHeader, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikInput, FormikPhoneInput, useUserContext} from '@reasoncorp/kyber-js';

import {OwnerPhoneNumber, QualifiedErrorResponse} from '../../types';
import {OwnerRequest, QualifiedErrorRequest} from '../../types/request';
import {qualifiedErrorOwnerSchema} from '../../schema/qualifiedError';

type Props = {
  qualifiedError: QualifiedErrorResponse
  onSave: (qualifiedErrorRequest: QualifiedErrorRequest) => void
}

type Values = {
  owner?: OwnerRequest
  ownerPhoneNumber: OwnerPhoneNumber
}

const QualifiedErrorOwnerCard = ({
                                   qualifiedError,
                                   onSave
                                 }: Props) => {
  const {permissions} = useUserContext();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleOpen = () => setModalIsOpen(!modalIsOpen);

  const initialValues = {
    owner: qualifiedError.owner,
    ownerPhoneNumber: {
      owner: qualifiedError?.ownerPhoneNumber?.owner ?? '',
      coOwner: qualifiedError?.ownerPhoneNumber?.coOwner ?? ''
    }
  };

  const handleSave = async (values: Values) => {
    const qualifiedErrorRequest = {
      ...qualifiedError,
      ...values
    } as QualifiedErrorRequest;
    await onSave(qualifiedErrorRequest);
    setModalIsOpen(false);
  };

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            Owner Information
          </Col>
          <Col sm="6" className="justify-content-end d-flex">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th className="w-25">Owner</th>
            <th className="w-25">Owner Phone #</th>
            <th className="w-25">Co-Owner</th>
            <th className="w-25">Co-Owner Phone #</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{qualifiedError.owner.ownerName}&nbsp;</td>
            <td>{qualifiedError.ownerPhoneNumber.owner}&nbsp;</td>
            <td>{qualifiedError.owner.coOwnerName}&nbsp;</td>
            <td>{qualifiedError.ownerPhoneNumber.coOwner}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={toggleOpen}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="lg">
      <ModalHeader toggle={toggleOpen}
                   tag="h2"
                   className="h5">
        Edit Owner Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={qualifiedErrorOwnerSchema}>
        {(formikProps) => (<Form onSubmit={formikProps.submitForm} autoComplete="off">
          <ModalBody>
            <Row>
              <Col sm="4">
                <FormikInput name="owner.ownerFirstName"
                             autoFocus
                             labelText="Owner First Name"/>
              </Col>
              <Col sm="4">
                <FormikInput name="owner.ownerMiddleName"
                             labelText="Owner Middle Name"/>
              </Col>
              <Col sm="4">
                <FormikInput name="owner.ownerLastName"
                             labelText="Owner Last Name"/>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <FormikInput name="owner.coOwnerFirstName"
                             labelText="Co-Owner First Name"/>
              </Col>
              <Col sm="4">
                <FormikInput name="owner.coOwnerMiddleName"
                             labelText="Co-Owner Middle Name"/>
              </Col>
              <Col sm="4">
                <FormikInput name="owner.coOwnerLastName"
                             labelText="Co-Owner Last Name"/>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <FormikPhoneInput name="ownerPhoneNumber.owner"
                                  labelText="Owner Phone #"/>
              </Col>
              <Col sm="6">
                <FormikPhoneInput name="ownerPhoneNumber.coOwner"
                                  labelText="Co-Owner Phone #"/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-end">
            <Button color="primary"
                    className="mr-2"
                    disabled={!formikProps.isValid || formikProps.isSubmitting || !formikProps.dirty}
                    onClick={formikProps.submitForm}>
              Save
            </Button>
            <Button color="secondary"
                    onClick={toggleOpen}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>)}
      </Formik>
    </Modal>
  </>;
};

export default QualifiedErrorOwnerCard;